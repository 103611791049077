// @flow
'use strict';

import React, { useState } from 'react';

type Props = {
  content: any,
  position?: string,
  children?: any
};

const Hint = (props: Props) => {
  const [hovering, setHovering] = useState<boolean>(false);
  const [hoveringContent, setHoveringContent] = useState<boolean>(false);

  const handleHoverIn = () => setHovering(true);
  const handleHoverOut = () => setTimeout(() => setHovering(false), 250);

  const handleHoverContentIn = () => setHoveringContent(true);
  const handleHoverContentOut = () => setHoveringContent(false);

  return (
    <div className="hint-wrapper" onMouseOver={ handleHoverIn } onMouseOut={ handleHoverOut }>
      { props.children }
      {
        hovering || hoveringContent ? (
          <div className='hint-popover-container'>
            <div className={ `hint-popover ${ props.position ? `positioned-${ props.position }` : `` }` } onMouseOver={ handleHoverContentIn } onMouseOut={ handleHoverContentOut }>
              { props.content }
            </div>
          </div>
        ) : ``
      }
    </div>
  );
  
};

export default Hint;
