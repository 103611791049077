'use strict';

import SvgHelper from '../helpers/SvgHelper.jsx';

/* eslint-disable */
export default SvgHelper(
  `<path fill="currentColor" d="M131.4 498.4v-173l79.9-43.4-22.9-15.3V131.4L303 209.9c10.9 7.4 17 19.7 16.3 32.9s-8 24.7-19.5 31L279 285l58.8 39.2c11 7.4 17.3 19.7 16.7 32.9-.6 13.3-7.9 24.9-19.6 31.3l-203.5 110zm35-152.2v93.5l151.8-82.1c.3-.2 1.2-.7 1.3-2.1.1-1.4-.8-2-1.1-2.2l-74.2-49.4-77.8 42.3zm56.9-98.2 22.7 15.1 37-20.1c.3-.2 1.2-.7 1.3-2.1.1-1.4-.8-2-1.1-2.2l-59.9-41V248zm310 63v-2c9.5-2.2 23.1-11.2 22.5-38.9-.7-30.1-24.1-43.2-55.6-43.2h-77v173.8H507c32 0 57.9-16.2 57.9-48.5 0-29.8-20.5-38.6-31.6-41.2zm-73.7-54h35.2c15.3 0 25.9 5.2 25.9 20.4s-10.6 20.4-25.9 20.4h-35.2V257zm41.9 113.7h-41.9v-42.8h41.9c15.9 0 27.4 5.6 27.4 21.5 0 15.8-11.5 21.3-27.4 21.3zm137.4-99.2c-30.6 0-50.5 11.9-56.7 30.8-1.3 3.9-1.8 8-1.8 12.1h32.9c1.1-11.5 9.8-17.5 25.1-17.5s22.8 6 22.8 19.8v2.3l-42.7 5.3c-25 3-44.9 14.5-44.9 39.9 0 24.4 19.4 38.7 48.2 38.7 23.8 0 34.4-8 38.7-15.2h1.9c0 7.2 5.8 13 13 13h19.8v-79.2c0-34.4-21.5-50-56.3-50zm22.3 76.4c0 19.6-13.3 29.1-31.6 29.1-13 0-20.6-5.8-20.6-14.8 0-8.9 7.2-13.4 17.6-14.8l34.6-4.6v5.1zm177-73.5h-40.8l-49.1 46.5h-1.9v-61.7c0-5-2.4-9.6-6.5-12.4l-29-19.8v173.8h35.5v-50.6h1.9l48.3 50.6h42l-61.3-66.3 60.9-60.1zm220.4 26.8v-26.8h-33.9v-15.2c0-5-2.4-9.6-6.5-12.4l-29-19.8v47.4H933l-49.1 46.5H882v-61.7c0-5-2.4-9.6-6.5-12.4l-29-19.8v173.8H882v-50.6h1.9l48.3 50.6h42L913 334.5l35.5-33.3h40.8v65.1c0 22.4 14.1 34.5 36.9 34.5h32.5V372h-32l-1.9-1.9v-68.9h33.8zm16.7 78.9h18.8v4.4h-6.8v16.3h-5.3v-16.3h-6.8v-4.4zm21.2 0h7.4l5.1 13.2h.4l5.1-13.2h7.4v20.7h-5.2V388h-.4l-5 12.8h-4.3l-4.9-12.8h-.4v12.8h-5.2v-20.7z"/>`,
  {
    width: `1190.1px`,
    height: `629.9px`,
    viewBox: `0 0 1190.1 629.9`
  }
);
/* eslint-enable */
