export const JSON_SCHEMA_STEPS = [
  {
    "type": `object`,
    "$id": `http://json-schema.org/draft-04/schema#clientAccountId`,
    "headerText": `Account Identification`,
    "submitText": `Next: Account Goals >>`,
    "required": [
      `accountType`
    ],
    "properties": {
      // "clientAccountId": {
      //   "title": `Client Account ID`,
      //   "type": `string`,
      //   "x-order": 1,
      //   "description": `Client-specified unique identifier for the account (clientAccountId)`
      // },
      "accountType": {
        "description": `Customer account type`,
        "type": `string`,
        "anyOf": [
          {
            "type": `string`,
            "title": `Individual`,
            "enum": [`INDIVIDUAL`],
          },
          {
            "type": `string`,
            "title": `Custodial`,
            "enum": [`CUSTODIAL`],
          },
          {
            "type": `string`,
            "title": `Entity Personal Trust`,
            "enum": [`ENTITY_PERSONAL_TRUST`],
          }
        ],
        "x-order": 2
      },
    }
  },
  {
    "type": `object`,
    "$id": `http://json-schema.org/draft-04/schema#riskTolerance`,
    "propertyGroup": `investmentProfile|accountGoals`,
    "submitText": `Next: Income >>`,
    "headerText": `Understanding Your Investment Journey`,
    "required": [
      `investmentObjective`
    ],
    "properties": {
      "investmentObjective": {
        "description": `Your primary investment objective should reflect your risk appetite, investment horizon, and overall financial situation.`,
        "type": `string`,
        "anyOf": [
          {
            "type": `string`,
            "title": `Balanced`,
            "enum": [`BALANCED`],
          },
          {
            "type": `string`,
            "title": `Capital Preservation`,
            "enum": [`CAPITAL_PRESERVATION`],
          },
          {
            "type": `string`,
            "title": `Growth`,
            "enum": [`GROWTH`],
          },
          {
            "type": `string`,
            "title": `Income`,
            "enum": [`INCOME`],
          },
          {
            "type": `string`,
            "title": `Speculation`,
            "enum": [`SPECULATION`],
          },
          {
            "type": `string`,
            "title": `Other`,
            "enum": [`OTHER`],
          },
        ],
        "x-readme-ref-name": `InvestmentObjective`
      },
      "riskTolerance": {
        "description": `<p>Low = Conservative: I am interested in preserving my initial principal with minimal risk.</p>
<p>Medium = Moderate: I am willing to accept some risk to my principal and tolerate some volatility while seeking higher returns. I understand I could lose a portion of the money I invest.</p>
<p>High = Aggressive: I am willing to accept maximum risk to my principal while aggressively seeking maximum returns. I understand I could lose most, or all, of the money I invest.</p>`,
        "type": `string`,
        "anyOf": [
          {
            "type": `string`,
            "title": `Low`,
            "enum": [`LOW`],
          },
          {
            "type": `string`,
            "title": `Medium`,
            "enum": [`MEDIUM`],
          },
          {
            "type": `string`,
            "title": `High`,
            "enum": [`HIGH`],
          },
        ],
        "x-readme-ref-name": `RiskTolerance`
      },
      "liquidityNeeds": {
        "description": `Assess your risk tolerance and comfort level with illiquid investments versus maintaining liquidity for short-term needs when evaluating your liquidity requirements.`,
        "type": `string`,
        "anyOf": [
          {
            "type": `string`,
            "title": `Very Important`,
            "enum": [`VERY_IMPORTANT`],
          },
          {
            "type": `string`,
            "title": `Somewhat Important`,
            "enum": [`SOMEWHAT_IMPORTANT`],
          },
          {
            "type": `string`,
            "title": `Not Important`,
            "enum": [`NOT_IMPORTANT`],
          },
        ],
        "x-readme-ref-name": `LiquidityNeeds`
      },
      "timeHorizon": {
        "description": `The length of time for which you plan to trade/invest with this account.`,
        "type": `string`,
        "anyOf": [
          {
            "type": `string`,
            "title": `Short`,
            "enum": [`SHORT`],
          },
          {
            "type": `string`,
            "title": `Average`,
            "enum": [`AVERAGE`],
          },
          {
            "type": `string`,
            "title": `Long`,
            "enum": [`LONG`],
          },
        ],
        "x-readme-ref-name": `TimeHorizon`
      }
    }
  },
  {
    "type": `object`,
    "$id": `http://json-schema.org/draft-04/schema#customerProfile`,
    "propertyGroup": `investmentProfile|customerProfile`,
    "submitText": `Next: Contact Information >>`,
    "headerText": `Defining Your Investment Profile`,
    "required": [
      `annualIncomeRangeUsd`
    ],
    "properties": {
      "liquidNetWorthRangeUsd": {
        "title": `Liquid Net Worth Range (USD)`,
        "description": `Your liquid net worth is the amount of money you have in cash or cash equivalents (assets that can be easily converted into cash).`,
        "type": `string`,
        "anyOf": [
          {
            "type": `string`,
            "title": `Under 25k`,
            "enum": [`UNDER_25K`],
          },
          {
            "type": `string`,
            "title": `From 25k To 50k`,
            "enum": [`FROM_25K_TO_50K`],
          },
          {
            "type": `string`,
            "title": `From 50k To 100k`,
            "enum": [`FROM_50K_TO_100K`],
          },
          {
            "type": `string`,
            "title": `From 100k To 200k`,
            "enum": [`FROM_100K_TO_200K`],
          },
          {
            "type": `string`,
            "title": `From 200k To 300k`,
            "enum": [`FROM_200K_TO_300K`],
          },
          {
            "type": `string`,
            "title": `From 300k To 500k`,
            "enum": [`FROM_300K_TO_500K`],
          },
          {
            "type": `string`,
            "title": `From 500k To 1m`,
            "enum": [`FROM_500K_TO_1M`],
          },
          {
            "type": `string`,
            "title": `From 1m To 5m`,
            "enum": [`FROM_1M_TO_5M`],
          },
          {
            "type": `string`,
            "title": `Over 5m`,
            "enum": [`OVER_5M`],
          },          
        ],
        "x-readme-ref-name": `USDRange`
      },
      "federalTaxBracket": {
        "type": `integer`,
        "description": `<p>You pay tax as a percentage of your income in layers called tax brackets. As your income goes up, the tax rate on the next layer of income is higher.</p>
<p>When your income jumps to a higher tax bracket, you don't pay the higher rate on your entire income. You pay the higher rate only on the part that's in the new tax bracket.
To find more about which tax bracket you fall into, click the following link 
<a href="https://smartasset.com/taxes/current-federal-income-tax-brackets" target="_blank">https://smartasset.com/taxes/current-federal-income-tax-brackets</a></p>`,
        "anyOf": [
          {
            "type": `integer`,
            "title": `10%`,
            "enum": [10],
          },
          {
            "type": `integer`,
            "title": `12%`,
            "enum": [12],
          },
          {
            "type": `integer`,
            "title": `22%`,
            "enum": [22],
          },
          {
            "type": `integer`,
            "title": `24%`,
            "enum": [24],
          },
          {
            "type": `integer`,
            "title": `32%`,
            "enum": [32],
          },
          {
            "type": `integer`,
            "title": `35%`,
            "enum": [35],
          },
          {
            "type": `integer`,
            "title": `37%`,
            "enum": [37],
          },
        ],
      },
      "sourceOfFunds": {
        "type": `string`,
        "description": `For example, earning from your employment, rental income or other descriptive source of how you obtained the money you are using to fund the account.`
      },
      "annualIncomeRangeUsd": {
        "title": `Annual Income Range (USD)`,
        "type": `string`,
        "anyOf": [
          {
            "type": `string`,
            "title": `Under 25k`,
            "enum": [`UNDER_25K`],
          },
          {
            "type": `string`,
            "title": `From 25k To 50k`,
            "enum": [`FROM_25K_TO_50K`],
          },
          {
            "type": `string`,
            "title": `From 50k To 100k`,
            "enum": [`FROM_50K_TO_100K`],
          },
          {
            "type": `string`,
            "title": `From 100k To 200k`,
            "enum": [`FROM_100K_TO_200K`],
          },
          {
            "type": `string`,
            "title": `From 200k To 300k`,
            "enum": [`FROM_200K_TO_300K`],
          },
          {
            "type": `string`,
            "title": `From 300k To 500k`,
            "enum": [`FROM_300K_TO_500K`],
          },
          {
            "type": `string`,
            "title": `From 500k To 1m`,
            "enum": [`FROM_500K_TO_1M`],
          },
          {
            "type": `string`,
            "title": `From 1m To 5m`,
            "enum": [`FROM_1M_TO_5M`],
          },
          {
            "type": `string`,
            "title": `Over 5m`,
            "enum": [`OVER_5M`],
          },          
        ],
        "x-readme-ref-name": `USDRange`
      },
      "totalNetWorthRangeUsd": {
        "title": `Total Net Worth Range (USD)`,
        "description": `Your total net worth is the total value of your assets minus your liabilities.`,
        "type": `string`,
        "anyOf": [
          {
            "type": `string`,
            "title": `Under 25k`,
            "enum": [`UNDER_25K`],
          },
          {
            "type": `string`,
            "title": `From 25k To 50k`,
            "enum": [`FROM_25K_TO_50K`],
          },
          {
            "type": `string`,
            "title": `From 50k To 100k`,
            "enum": [`FROM_50K_TO_100K`],
          },
          {
            "type": `string`,
            "title": `From 100k To 200k`,
            "enum": [`FROM_100K_TO_200K`],
          },
          {
            "type": `string`,
            "title": `From 200k To 300k`,
            "enum": [`FROM_200K_TO_300K`],
          },
          {
            "type": `string`,
            "title": `From 300k To 500k`,
            "enum": [`FROM_300K_TO_500K`],
          },
          {
            "type": `string`,
            "title": `From 500k To 1m`,
            "enum": [`FROM_500K_TO_1M`],
          },
          {
            "type": `string`,
            "title": `From 1m To 5m`,
            "enum": [`FROM_1M_TO_5M`],
          },
          {
            "type": `string`,
            "title": `Over 5m`,
            "enum": [`OVER_5M`],
          },          
        ],
        "x-readme-ref-name": `USDRange`
      },
      "investmentExperience": {
        "description": `Your investment experience encompasses your knowledge, expertise, and past involvement in financial markets.`,
        "type": `string`,
        "anyOf": [
          {
            "type": `string`,
            "title": `None`,
            "enum": [`NONE`],
          },
          {
            "type": `string`,
            "title": `Limited`,
            "enum": [`LIMITED`],
          },
          {
            "type": `string`,
            "title": `Good`,
            "enum": [`GOOD`],
          },
          {
            "type": `string`,
            "title": `Extensive`,
            "enum": [`EXTENSIVE`],
          }       
        ],
        "x-readme-ref-name": `InvestmentExperience`
      }
    },
  },
  {
    "type": `object`,
    "$id": `http://json-schema.org/draft-04/schema#owners`,
    "submitText": `Next: Trusted Contacts >>`,
    "headerText": `Account Owner Information`,
    "properties": {
      "owners": {
        "x-order": 5,
        "type": `array`,
        "minItems": 1,
        "maxItems": 5,
        "items": {
          "type": `object`,
          "title": `Owner`,
          "required": [
            `emailAddress`,
            `mailingAddress`,
            `role`,
            `identity`,
            // `agreements`
          ],
          "properties": {
            "role": {
              "type": `string`,
              "anyOf": [
                {
                  "type": `string`,
                  "title": `Authorized Agent`,
                  "enum": [`AUTHORIZED_AGENT`],
                },
                {
                  "type": `string`,
                  "title": `Authorized Member`,
                  "enum": [`AUTHORIZED_MEMBER`],
                },
                {
                  "type": `string`,
                  "title": `Authorized Owner`,
                  "enum": [`AUTHORIZED_OWNER`],
                },
                {
                  "type": `string`,
                  "title": `Beneficiary`,
                  "enum": [`BENEFICIARY`],
                },
                {
                  "type": `string`,
                  "title": `Custodian`,
                  "enum": [`CUSTODIAN`],
                },
                {
                  "type": `string`,
                  "title": `Education Beneficiary`,
                  "enum": [`EDUCATION_BENEFICIARY`],
                },
                {
                  "type": `string`,
                  "title": `Education Manager`,
                  "enum": [`EDUCATION_MANAGER`],
                },
                {
                  "type": `string`,
                  "title": `Executor`,
                  "enum": [`EXECUTOR`],
                },
                {
                  "type": `string`,
                  "title": `Guardian Conservator`,
                  "enum": [`GUARDIAN_CONSERVATOR`],
                },
                {
                  "type": `string`,
                  "title": `Internal Power Of Atty`,
                  "enum": [`INTERNAL_POWER_OF_ATTY`],
                },
                {
                  "type": `string`,
                  "title": `Initial Depositor`,
                  "enum": [`INITIAL_DEPOSITOR`],
                },
                {
                  "type": `string`,
                  "title": `Joint Owner`,
                  "enum": [`JOINT_OWNER`],
                },
                {
                  "type": `string`,
                  "title": `Minor Owner`,
                  "enum": [`MINOR_OWNER`],
                },
                {
                  "type": `string`,
                  "title": `Primary Owner`,
                  "enum": [`PRIMARY_OWNER`],
                },
                {
                  "type": `string`,
                  "title": `Power Of Atty`,
                  "enum": [`POWER_OF_ATTY`],
                },
                {
                  "type": `string`,
                  "title": `Sole Proprietor`,
                  "enum": [`SOLE_PROPRIETOR`],
                },
                {
                  "type": `string`,
                  "title": `Trading Authority`,
                  "enum": [`TRADING_AUTHORITY`],
                },
                {
                  "type": `string`,
                  "title": `Treasurer`,
                  "enum": [`TREASURER`],
                },
                {
                  "type": `string`,
                  "title": `Trustee`,
                  "enum": [`TRUSTEE`],
                },
                {
                  "type": `string`,
                  "title": `Trusted Contact`,
                  "enum": [`TRUSTED_CONTACT`],
                }
              ],
              "x-readme-ref-name": `PartyRelationType`
            },
            "phoneNumber": {
              "description": `Customer's contact phone number (Should be in compacted format, US (+1) 1234 123456 becomes +1234123456 and UK (+44) 1234 123456 becomes +441234123456)`,
              "type": `string`,
              "x-order": 1
            },
            "emailAddress": {
              "type": `string`,
              // "format": `email`,
              "pattern": `^\\S+@\\S+\\.\\S+$`,
              "x-order": 2,
              "description": `Primary account owner’s email address.`
            },
            "mailingAddress": {
              "type": `object`,
              "required": [
                `city`,
                `country`,
                `postalCode`,
                `state`,
                `streetAddress`
              ],
              "properties": {
                "city": {
                  "description": `City of residence`,
                  "type": `string`,
                  "x-order": 0
                },
                "state": {
                  "description": `State of residence (two-letter state abbreviation). If outside of the United States please provide the province/territory in this field (no character limitation).`,
                  "type": `string`,
                  "x-order": 1
                },
                "postalCode": {
                  "x-order": 2,
                  "description": `Postal code / zip code`,
                  "type": `string`
                },
                "country": {
                  "description": `Country of residence (ISO code)`,
                  "type": `string`,
                  "x-order": 3
                },
                "streetAddress": {
                  "description": `Street address for primary residence (for employer, street address for place of employment)`,
                  "type": `array`,
                  "minItems": 1,
                  "maxItems": 5,
                  "items": {
                    "type": [`string`],
                    "title": `Street Address`,
                    "default": null,
                  },
                  "x-order": 4
                }
              },
              "x-readme-ref-name": `Address`
            },
            // "agreements": {
            //   "type": `array`,
            //   "items": {
            //     "title": `Agreement`,
            //     "type": `object`,
            //     "required": [
            //       `name`,
            //       `version`
            //     ],
            //     "properties": {
            //       "eSignedDate": {
            //         "type": `string`,
            //         "format": `date-time`,
            //         "x-order": 3,
            //         "description": `Date of electronic signature of the agreement`
            //       },
            //       "name": {
            //         "type": `string`,
            //         "x-order": 0,
            //         "description": `Name of the agreement document`
            //       },
            //       "version": {
            //         "description": `Agreement document version`,
            //         "type": `string`,
            //         "x-order": 1
            //       },
            //       "text": {
            //         "description": `Text containing the body of the agreement document`,
            //         "type": `string`,
            //         "x-order": 2
            //       }
            //     },
            //     "x-readme-ref-name": `Agreement`
            //   },
            //   "x-order": 7
            // },
            // "ownerId": {
            //   "title": `Owner ID`,
            //   "description": `Apex Crypto owner identifier`,
            //   "type": `string`,
            //   "format": `uuid`,
            //   "x-order": 0
            // }
            "identity": {
              "type": `object`,
              "required": [
                `citizenshipCountry`,
                `dateOfBirth`,
                `familyName`,
                `givenName`,
                `personalAddress`,
                `taxId`,
                `taxIdType`
              ],
              "properties": {
                "givenName": {
                  "description": `Customer's given (typically first) name`,
                  "type": `string`,
                  "x-order": 0
                },
                "middleNames": {
                  "description": `Customer's middle name(s). Space or comma separated.`,
                  "type": `string`,
                  "x-order": 1
                },
                "familyName": {
                  "description": `Customer's family (typically last) name`,
                  "type": `string`,
                  "x-order": 2
                },
                "dateOfBirth": {
                  "description": `Customer's date of birth`,
                  "type": `string`,
                  "format": `date`,
                  "x-order": 3
                },
                "maritalStatus": {
                  "description": `Customer's marital status`,
                  "type": `string`,
                  "anyOf": [
                    {
                      "type": `string`,
                      "title": `Single`,
                      "enum": [`SINGLE`],
                    },
                    {
                      "type": `string`,
                      "title": `Married`,
                      "enum": [`MARRIED`],
                    },
                    {
                      "type": `string`,
                      "title": `Divorced`,
                      "enum": [`DIVORCED`],
                    },
                    {
                      "type": `string`,
                      "title": `Widowed`,
                      "enum": [`WIDOWED`],
                    }
                  ],
                  "x-readme-ref-name": `MaritalStatus`
                },
                "taxIdType": {
                  "description": `Your tax ID type refers to the form of identification used for tax reporting purposes. Choose the option that corresponds to the type of tax ID you possess.`,
                  "type": `string`,
                  "enum": [
                    `SSN`,
                    `ITIN`,
                    `EIN`
                  ],
                  "x-readme-ref-name": `TaxIdType`
                },
                "taxId": {
                  "title": `Tax ID`,
                  "description": `Owner's tax ID (SSN/EIN/ITIN)`,
                  "type": `string`,
                  "x-order": 4
                },
                "citizenshipCountry": {
                  "type": `string`,
                  "x-order": 6,
                  "description": `Your citizenship country refers to the nation whose citizenship you hold.`
                },
                "personalAddress": {
                  "type": `object`,
                  "properties": {
                    "isSameMailingAddress": {
                      "type": `boolean`,
                      "title": `Select if Personal address is same as mailing address`,
                      "default": false
                    },
                  },
                  "allOf": [
                    {
                      "if": {
                        "properties": {
                          "isSameMailingAddress": {
                            "const": false
                          }
                        }
                      },
                      "then": {
                        "required": [
                          `city`,
                          `country`,
                          `postalCode`,
                          `state`,
                          `streetAddress`
                        ],
                        "properties": {
                          "city": {
                            "description": `City of residence`,
                            "type": `string`,
                            "x-order": 0
                          },
                          "state": {
                            "description": `State of residence (two-letter state abbreviation). If outside of the United States please provide the province/territory in this field (no character limitation).`,
                            "type": `string`,
                            "x-order": 1
                          },
                          "postalCode": {
                            "x-order": 2,
                            "description": `Postal code / zip code`,
                            "type": `string`
                          },
                          "country": {
                            "description": `Country of residence (ISO code)`,
                            "type": `string`,
                            "x-order": 3
                          },
                          "streetAddress": {
                            "description": `Street address for primary residence (for employer, street address for place of employment)`,
                            "type": `array`,
                            "minItems": 1,
                            "maxItems": 5,
                            "items": {
                              "type": [`string`],
                              "title": `Street Address`,
                              "default": null,
                            },
                            "x-order": 4
                          }
                        },
                      }
                    },
                  ],
                  "x-readme-ref-name": `Address`
                },
                "employment": {
                  "type": `object`,
                  "required": [
                    `employerAddress`,
                    `employerName`,
                    `position`,
                    `yearsEmployed`
                  ],
                  "properties": {
                    "employerName": {
                      "description": `Name of customer's employer`,
                      "type": `string`,
                      "x-order": 0
                    },
                    "position": {
                      "description": `Customer's employment position`,
                      "type": `string`,
                      "x-order": 1
                    },
                    "yearsEmployed": {
                      "description": `Number of years customer employed with employer listed`,
                      "type": `number`,
                      "minimum": 0,
                      "x-order": 2
                    },
                    "employerAddress": {
                      "type": `object`,
                      "required": [
                        `city`,
                        `country`,
                        `postalCode`,
                        `state`,
                        `streetAddress`
                      ],
                      "properties": {
                        "city": {
                          "description": `City of residence`,
                          "type": `string`,
                          "x-order": 0
                        },
                        "state": {
                          "description": `State of residence (two-letter state abbreviation). If outside of the United States please provide the province/territory in this field (no character limitation).`,
                          "type": `string`,
                          "x-order": 1
                        },
                        "postalCode": {
                          "x-order": 2,
                          "description": `Postal code / zip code`,
                          "type": `string`
                        },
                        "country": {
                          "description": `Country of residence (ISO code)`,
                          "type": `string`,
                          "x-order": 3
                        },
                        "streetAddress": {
                          "description": `Street address for primary residence (for employer, street address for place of employment)`,
                          "type": `array`,
                          "minItems": 1,
                          "maxItems": 5,
                          "items": {
                            "type": [`string`],
                            "title": `Street Address`,
                            "default": null,
                          },
                          "x-order": 4
                        }
                      },
                      "x-readme-ref-name": `Address`
                    }
                  },
                  "x-readme-ref-name": `Employment`
                },
                "disclosures": {
                  "type": `object`,
                  "properties": {
                    "controlPersonCompanySymbols": {
                      "description": `Stock symbols of publicly traded companies for which the detailed customer is listed as a control person; comma separated`,
                      "type": `string`,
                      "x-order": 0
                    },
                    "finraAssociatedEntities": {
                      "description": `Names of the FINRA-associated entities or FINRA-regulated broker dealers; comma separated`,
                      "type": `string`,
                      "x-order": 1
                    },
                    "politicallyExposedOrganizations": {
                      "description": `Names of any political organizations you are affiliated with; separated by commas.`,
                      "type": `string`,
                      "x-order": 2
                    },
                    "politicallyExposedImmediateFamilyNames": {
                      "description": `(Full) names of politically exposed family members including parent, child, spouse, cousin, etc.; comma separated`,
                      "type": `string`,
                      "x-order": 3
                    },
                    "politicallyExposed": {
                      "description": `Customer's political exposure status`,
                      "type": [
                        `string`,
                        `null`
                      ],
                      "anyOf": [
                        {
                          "type": `string`,
                          "title": `Unknown`,
                          "enum": [`UNKNOWN`],
                        },
                        {
                          "type": `string`,
                          "title": `Possible`,
                          "enum": [`POSSIBLE`],
                        },
                        {
                          "type": `string`,
                          "title": `Exact`,
                          "enum": [`EXACT`],
                        },
                        {
                          "type": `string`,
                          "title": `No Match`,
                          "enum": [`NO_MATCH`],
                        }
                      ],
                      "x-order": 4
                    },
                    "ofacSanctioned": {
                      "x-order": 5,
                      "title": `OFAC Sanctioned`,
                      "description": `The Office of Foreign Assets Control administers and enforces economic sanctions programs primarily against countries and groups of individuals, 
such as terrorists and narcotics traffickers.`,
                      "type": [
                        `string`,
                        `null`
                      ],
                      "anyOf": [
                        {
                          "type": `string`,
                          "title": `Unknown`,
                          "enum": [`UNKNOWN`],
                        },
                        {
                          "type": `string`,
                          "title": `Possible`,
                          "enum": [`POSSIBLE`],
                        },
                        {
                          "type": `string`,
                          "title": `Exact`,
                          "enum": [`EXACT`],
                        },
                        {
                          "type": `string`,
                          "title": `No Match`,
                          "enum": [`NO_MATCH`],
                        }
                      ],
                    },
                    "lastScreenedDate": {
                      "description": `Last screened for customer due diligence - a process that financial institutions, businesses, 
and other organizations use to gather information about their customers and clients in order to identify and mitigate risks such as money laundering, financing terrorism, 
and other illicit activities.`,
                      "type": `string`,
                      "format": `date-time`,
                      "x-order": 6
                    },
                  },
                  "x-readme-ref-name": `Disclosures`
                },
                // "verificationResults": {
                //   "required": [
                //     `addressVerified`, //customValidate
                //     `dateOfBirthVerified`, //customValidate
                //     `executionDate`,
                //     `externalCaseId`,
                //     `nameVerified`, //customValidate
                //     `taxIdVerified`, //customValidate
                //     `vendorName`
                //   ],
                //   "properties": {
                //     "executionDate": {
                //       "description": `The date the KYC was performed`,
                //       "type": `string`,
                //       "format": `date`,
                //       "x-order": 3
                //     },
                //     "dateOfBirthVerified": {
                //       "description": `Indicates if the customer's DOB has been verified`,
                //       "type": `boolean`,
                //       "default": null,
                //       "enum": [null, true],
                //       "x-order": 7
                //     },
                //     "manualOverrideReason": {
                //       "description": `Reason for account approval override`,
                //       "type": `string`,
                //       "x-order": 9
                //     },
                //     "identityVerificationResultId": {
                //       "title": `Identity Verification Result ID`,
                //       "type": `string`,
                //       "x-order": 0
                //     },
                //     "externalCaseId": {
                //       "x-order": 2,
                //       "title": `External Case ID`,
                //       "description": `Client-generated unique identifier associated with the KYC results for the appropriate case`,
                //       "type": `string`
                //     },
                //     "nameVerified": {
                //       "description": `Indicates if the customer's name has been verified`,
                //       "type": `boolean`,
                //       "default": null,
                //       "enum": [null, true],
                //       "x-order": 4
                //     },
                //     "addressVerified": {
                //       "description": `Indicates if the customer's address has been verified`,
                //       "type": `boolean`,
                //       "default": null,
                //       "enum": [null, true],
                //       "x-order": 5
                //     },
                //     "taxIdVerified": {
                //       "title": `Tax ID Verified`,
                //       "description": `Indicates if the customer's taxId has been verified`,
                //       "type": `boolean`,
                //       "default": null,
                //       "enum": [null, true],
                //       "x-order": 6
                //     },
                //     "manualOverride": {
                //       "type": `boolean`,
                //       "default": null,
                //       "enum": [null, true],
                //       "x-order": 8,
                //       "description": `Indicates account failed vendor verification but has been manually reviewed and approved`
                //     },
                //     "manualOverrideApprover": {
                //       "description": `Individual who approved the override`,
                //       "type": `string`,
                //       "x-order": 10
                //     },
                //     "vendorName": {
                //       "description": `If client is providing KYC information, this section will be filled out with the relevant details. Note that individual client implementations will vary.`,
                //       "type": `string`,
                //       "x-order": 1
                //     }
                //   },
                //   "type": `object`,
                //   "x-readme-ref-name": `VerificationResults`
                // },
              },
              "x-readme-ref-name": `Identity`
            },
          },
          "x-readme-ref-name": `Owner`
        }
      },
    }
  },
  {
    "type": `object`,
    "$id": `http://json-schema.org/draft-04/schema#trustedContacts`,
    "submitText": `Next: Sign Agreement >>`,
    "headerText": `Trusted Contact Information`,
    "properties": {
      "trustedContacts": {
        "type": `array`,
        "items": {
          "title": `Trusted Contact`,
          "type": `object`,
          "required": [
            `givenName`,
            `familyName`
          ],
          "properties": {
            "givenName": {
              "description": `The trusted contact's given (typically first) name`,
              "type": `string`,
              "x-order": 0
            },
            "middleNames": {
              "x-order": 1,
              "description": `The trusted contact's middle name(s). Space or comma separated.`,
              "type": `string`
            },
            "familyName": {
              "description": `The trusted contact's family (typically last) name`,
              "type": `string`,
              "x-order": 2
            },
            "phoneNumber": {
              "description": `The trusted contact's phone number`,
              "type": `string`,
              "x-order": 3
            },
            "emailAddress": {
              "description": `The trusted contact's email address`,
              "type": `string`,
              // "format": `email`,
              "pattern": `^\\S+@\\S+\\.\\S+$`,
              "x-order": 4
            },
            "mailingAddress": {
              "type": `object`,
              "required": [
                `city`,
                `country`,
                `postalCode`,
                `state`,
                `streetAddress`
              ],
              "properties": {
                "city": {
                  "description": `City of residence`,
                  "type": `string`,
                  "x-order": 0
                },
                "state": {
                  "description": `State of residence (two-letter state abbreviation). If outside of the United States please provide the province/territory in this field (no character limitation).`,
                  "type": `string`,
                  "x-order": 1
                },
                "postalCode": {
                  "x-order": 2,
                  "description": `Postal code / zip code`,
                  "type": `string`
                },
                "country": {
                  "description": `Country of residence (ISO code)`,
                  "type": `string`,
                  "x-order": 3
                },
                "streetAddress": {
                  "description": `Street address for primary residence (for employer, street address for place of employment)`,
                  "type": `array`,
                  "minItems": 1,
                  "maxItems": 5,
                  "items": {
                    "type": [`string`],
                    "title": `Street Address`,
                    "default": null,
                  },
                  "x-order": 4
                }
              },
              "x-readme-ref-name": `Address`
            }
          },
          "x-readme-ref-name": `TrustedContact`
        },
        "x-order": 6
      },
    }
  }
];

export const AGREEMENT = {
  name: `Agreement Name`,
  version: `0001b`,
  text: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, 
when an unknown printer took a galley of type and scrambled it to make a type specimen book.
It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. 
It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, 
and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.`
};
