// @flow
'use strict';

import React from 'react';

const GenericError = () => (
  <div className="generic-error">
    <div className="error-container">
      <div className="error-content">
        <img className="error-logo" src={ window.WWW_URL + `/assets/home/images/logo-new.svg` } />
        <h1 data-h1="Oops! We&apos;ve encountered an error.">
      Oops! We&apos;ve encountered an error.
        </h1>
        <h2>
          <b>
Please refresh the browser and try again.
          </b>
          <br/>
If you continue to encounter this issue, please contact support.
          <br/>
This event has been reported to our technical team for analysis.
        </h2>
        <button onClick={
          () => {
            window.location.href = `/markets/CBSE/BTC/USD`;
          }
        }>
Go Back
        </button>
      </div>
    </div>
  </div>
);

export default GenericError;
