/*
 * Copyright (C) 2023 PixieBrix, Inc.
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

import { Validator } from "@cfworker/json-schema";

import {
  createErrorHandler,
  getDefaultFormState,
  toErrorList,
  toErrorSchema,
  unwrapErrorHandler,
  validationDataMerge,
  withIdRefPrefix,
} from "@rjsf/utils";

const REQUIRED_PROPERTY_NAME_REGEX =
  /Instance does not have required property "(.*?)"/;
const HASH_OR_HASH_SLASH_REGEX = /^#\/?/;


const draft04 = {
  "id": `http://json-schema.org/draft-04/schema#`,
  "$schema": `http://json-schema.org/draft-04/schema#`,
  "description": `Core schema meta-schema`,
  "definitions": {
    "schemaArray": {
      "type": `array`,
      "minItems": 1,
      "items": {
        "$ref": `#`
      }
    },
    "positiveInteger": {
      "type": `integer`,
      "minimum": 0
    },
    "positiveIntegerDefault0": {
      "allOf": [
        {
          "$ref": `#/definitions/positiveInteger`
        },
        {
          "default": 0
        }
      ]
    },
    "simpleTypes": {
      "enum": [
        `array`,
        `boolean`,
        `integer`,
        `null`,
        `number`,
        `object`,
        `string`
      ]
    },
    "stringArray": {
      "type": `array`,
      "items": {
        "type": `string`
      },
      "minItems": 1,
      "uniqueItems": true
    }
  },
  "type": `object`,
  "properties": {
    "id": {
      "type": `string`
    },
    "$schema": {
      "type": `string`
    },
    "title": {
      "type": `string`
    },
    "description": {
      "type": `string`
    },
    "default": {
      
    },
    "multipleOf": {
      "type": `number`,
      "minimum": 0,
      "exclusiveMinimum": true
    },
    "maximum": {
      "type": `number`
    },
    "exclusiveMaximum": {
      "type": `boolean`,
      "default": false
    },
    "minimum": {
      "type": `number`
    },
    "exclusiveMinimum": {
      "type": `boolean`,
      "default": false
    },
    "maxLength": {
      "$ref": `#/definitions/positiveInteger`
    },
    "minLength": {
      "$ref": `#/definitions/positiveIntegerDefault0`
    },
    "pattern": {
      "type": `string`,
      "format": `regex`
    },
    "additionalItems": {
      "anyOf": [
        {
          "type": `boolean`
        },
        {
          "$ref": `#`
        }
      ],
      "default": {
        
      }
    },
    "items": {
      "anyOf": [
        {
          "$ref": `#`
        },
        {
          "$ref": `#/definitions/schemaArray`
        }
      ],
      "default": {
        
      }
    },
    "maxItems": {
      "$ref": `#/definitions/positiveInteger`
    },
    "minItems": {
      "$ref": `#/definitions/positiveIntegerDefault0`
    },
    "uniqueItems": {
      "type": `boolean`,
      "default": false
    },
    "maxProperties": {
      "$ref": `#/definitions/positiveInteger`
    },
    "minProperties": {
      "$ref": `#/definitions/positiveIntegerDefault0`
    },
    "required": {
      "$ref": `#/definitions/stringArray`
    },
    "additionalProperties": {
      "anyOf": [
        {
          "type": `boolean`
        },
        {
          "$ref": `#`
        }
      ],
      "default": {
        
      }
    },
    "definitions": {
      "type": `object`,
      "additionalProperties": {
        "$ref": `#`
      },
      "default": {
        
      }
    },
    "properties": {
      "type": `object`,
      "additionalProperties": {
        "$ref": `#`
      },
      "default": {
        
      }
    },
    "patternProperties": {
      "type": `object`,
      "additionalProperties": {
        "$ref": `#`
      },
      "default": {
        
      }
    },
    "dependencies": {
      "type": `object`,
      "additionalProperties": {
        "anyOf": [
          {
            "$ref": `#`
          },
          {
            "$ref": `#/definitions/stringArray`
          }
        ]
      }
    },
    "enum": {
      "type": `array`,
      "minItems": 1,
      "uniqueItems": true
    },
    "type": {
      "anyOf": [
        {
          "$ref": `#/definitions/simpleTypes`
        },
        {
          "type": `array`,
          "items": {
            "$ref": `#/definitions/simpleTypes`
          },
          "minItems": 1,
          "uniqueItems": true
        }
      ]
    },
    "format": {
      "type": `string`
    },
    "allOf": {
      "$ref": `#/definitions/schemaArray`
    },
    "anyOf": {
      "$ref": `#/definitions/schemaArray`
    },
    "oneOf": {
      "$ref": `#/definitions/schemaArray`
    },
    "not": {
      "$ref": `#`
    }
  },
  "dependencies": {
    "exclusiveMaximum": [
      `maximum`
    ],
    "exclusiveMinimum": [
      `minimum`
    ]
  },
  "default": {
    
  }
};

class FormValidator<T,S,F> implements ValidatorType<T, S, F> {
  validateFormData(formData, schema, customValidate?, transformErrors?, uiSchema?) {
    const rootSchema = schema;

    const rawErrors = this.rawValidation(schema, formData);

    const { validationError } = rawErrors;
    let errors = this.transformRJSFValidationErrors(rawErrors.errors);

    // Remaining logic is copied from AJV6 validator and adapted to our code style
    // https://github.com/rjsf-team/react-jsonschema-form/blob/main/packages/validator-ajv6/src/validator.ts#L126

    const noProperMetaSchema = validationError?.message?.includes(
      `no schema with key or ref `,
    );

    if (noProperMetaSchema) {
      errors = [...errors, { stack: validationError.message }];
    }

    if (typeof transformErrors === `function`) {
      errors = transformErrors(errors, uiSchema);
    }

    let errorSchema = toErrorSchema(errors);

    if (noProperMetaSchema) {
      errorSchema = {
        ...errorSchema,
        $schema: {
          __errors: [validationError.message],
        },
      };
    }

    if (typeof customValidate !== `function`) {
      return { errors, errorSchema };
    }

    // Include form data with undefined values, which is required for custom validation.
    const newFormData = getDefaultFormState(
      this,
      schema,
      formData,
      rootSchema,
      true,
    );

    const errorHandler = customValidate(
      newFormData,
      createErrorHandler(newFormData),
      uiSchema,
    );
    const userErrorSchema = unwrapErrorHandler(errorHandler);
    return validationDataMerge({ errors, errorSchema }, userErrorSchema);
  }

  transformRJSFValidationErrors(errors= []) {
    return errors
      .filter(
        (error) =>
          error.instanceLocation !== `#` || error.keyword === `required`,
      )
      .map((outputUnit) => {
        const { keyword, keywordLocation, instanceLocation, error } =
          outputUnit;

        let property = ``;

        switch (keyword) {
        case `required`:
        case `format`: 
        case `minLength`:
        case `minimum`: 
        case `minItems`:
        case `pattern`: {
          const matches = REQUIRED_PROPERTY_NAME_REGEX.exec(error);

          property = `${instanceLocation.replace(
            HASH_OR_HASH_SLASH_REGEX,
            ``,
          )}.${matches?.[1] ?? ``}`;
          break;
        }

        case `type`: {
          property = instanceLocation.replace(HASH_OR_HASH_SLASH_REGEX, ``);
          break;
        }
        
        default: {
          property = keywordLocation
            .replace(`#/properties/`, ``)
            .replace(`/${keyword}`, ``);
        }
        
        }

        // Put data in expected format
        return {
          name: keyword,
          property: !property.startsWith(`.`) ? `.${property.replaceAll(`/`, `.`)}` : property,
          message: error,
          stack: `${keywordLocation} ${error}`.trim(),
          schemaPath: keywordLocation,
        };
      });
  }

  isValidSchema(schema) {
    const validator = new Validator(draft04);
  
    try {
      const result = validator.validate(schema);
      return result.valid;
    } catch {
      return false;
    }
  }
  

  isValid(schema, formData){
    if (!this.isValidSchema(schema)) {
      return false;
    }

    const validator = new Validator(
      withIdRefPrefix(schema),
      // The default used by RJSF: https://rjsf-team.github.io/react-jsonschema-form/docs/usage/validation/#custom-meta-schema-validation
      `7`,
      true,
    );

    const result = validator.validate(formData);
    return result.valid;
  }

  rawValidation(schema, formData?) {
    try {
      // Return all errors so they can be displayed in the form
      const validator = new Validator(schema, `7`, false);
      const result = validator.validate(formData);

      return {
        // Match casting behavior of AJV6 validator
        // https://github.com/rjsf-team/react-jsonschema-form/blob/main/packages/validator-ajv6/src/validator.ts#L99
        errors: result.errors,
      };
    } catch (error) {
      if (error instanceof Error) {
        return { validationError: error };
      }

      // $FlowIgnore: suppressing this error
      return { validationError: new Error(error) };
    }
  }

  toErrorList(errorSchema? , fieldPath = []) {
    return toErrorList<T>(errorSchema, fieldPath);
  }
}

const validator = new FormValidator();

export default validator;
