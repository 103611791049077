// @flow
'use strict';

import React, { useEffect } from 'react';
import { translate } from 'react-i18next';
//import PillToggle from '../utilities/PillToggle.jsx';
//import RadioButtons from '../utilities/RadioButtons.jsx';
import PresetTextField from '../utilities/PresetTextField.jsx';
import Button from '../utilities/Button.jsx';
import TextField from '../utilities/TextField.jsx';
import SelectField from '../utilities/SelectField.jsx';
import Checkbox from '../utilities/Checkbox.jsx';
import { getMarketPair } from '../../helpers/MarketPairHelper';
import { toFixedDecimals, toFixedDecimalsHTML } from '../../helpers/NumberHelper.js';
import type { Exchange } from '../../types/Exchange.js';
import type { Market } from '../../types/Market.js';
import type { Account } from '../../types/Account';
import Refresh from '../../svgs/Refresh.jsx';
import Trash2 from '../../svgs/Trash2.jsx';
import CollapseLeft from '../../svgs/CollapseLeft.jsx';
import CollapseRight from '../../svgs/CollapseRight.jsx';
import MarketSelectorModal from '../boards/MarketSelectorModal.jsx';

type Props = {
  t: any,
  markets: Array<Market>,
  exchanges: Array<Exchange>,
  active?: {
    exchange: Exchange,
    market: Market
  },
  options?: Array<{
    label: string,
    value: any,
    icon?: any,
    compareValue?: any
  }>,
  balanceOptions: Array<{
    label: string,
    value: any,
    compareValue?: any,
    icon?: any
  }>,
  apiVersion: number,
  activeAccounts: Array<Account>,
  tradingEnabled: boolean,
  mktId: number,
  exchId: number,
  authId: number,
  lastPrice: number,
  limitPrice: string,
  stopPrice: string,
  stopMarketId: number,
  stopMarketName: string,
  stopPricePrecision: number,
  setStopMarket: () => void,
  orderQty: string,
  orderTotal: string,
  orderTypeId: number,
  currencyText: string,
  priceTypeId: number,
  baseCurrencyText: string,
  limitPriceError?: string,
  stopPriceError?: string,
  orderQuantityError?: string,
  orderTotalError?: string,
  balanceData?: any,
  onChange: (key?: string, value: any, forceNumber?: boolean, callback?: () => void) => void,
  onSubmit: (e?: any) => boolean,
  addNewAccount?: () => void,
  accountStalenessClass: () => string,
  refreshBalance: () => void,
  refreshEnabled: () => boolean,
  refreshState: string,
  resetOrderForm: () => void,
  updateOrderFormType?: (orderFormType: string) => void,
  autoConfirm: boolean,
  isDetached: boolean,
  showMarketSelectorModal: () => void,
  hideMarketSelectorModal: () => void,
  marketSelectorModalOpen: boolean,
  updateInputDirtiness: (e: boolean) => void,
};

const OrderForm = ({
  t,
  markets,
  exchanges,
  active,
  options,
  balanceOptions,
  apiVersion,
  activeAccounts,
  tradingEnabled,
  balanceData,
  mktId,
  exchId,
  authId,
  lastPrice,
  limitPrice,
  stopPrice,
  orderQty,
  orderTotal,
  orderTypeId,
  currencyText,
  priceTypeId,
  baseCurrencyText,
  limitPriceError,
  stopPriceError,
  orderQuantityError,
  orderTotalError,
  onChange,
  onSubmit,
  addNewAccount,
  accountStalenessClass,
  refreshBalance,
  refreshEnabled,
  refreshState,
  resetOrderForm,
  updateOrderFormType,
  autoConfirm,
  isDetached,
  stopMarketId,
  stopMarketName,
  showMarketSelectorModal,
  hideMarketSelectorModal,
  marketSelectorModalOpen,
  setStopMarket,
  updateInputDirtiness
}: Props) => {

  useEffect(() => {
    updateInputDirtiness(false);
  }, []);

  return (
    <div>
      {
      // Exchange select box - only shown when on the Orders full page
        isDetached && (
          <div>
            <SelectField
              searchable={ true }
              label={ t(`app:exchange`) }
              name={ `exchId` }
              value={ exchId }
              options={ exchanges.filter((exch) => {
                return exch.exchTradeEnabled || exch.exchTradeEnabledV2;
              }).map((exch) => ({
                label: exch.exchName,
                compareValue: exch.exchId,
                value: {
                  exchId: exch.exchId,
                  mktId: -1,
                  authId: -1
                }
              })).sort((a, b) => {
                if (a.label > b.label) return 1;
                if (b.label > a.label) return -1;
                return 0;
              }) }
              onChange={ (e, v) => { 
                onChange(undefined, v);
              } 
              } />
          </div>
        )
      }
      {
      // Market select box - only shown on Orders full page
        isDetached && (
          <div>
            <SelectField
              searchable={ true }
              label={ t(`app:market`) }
              name={ `mktId` }
              value={ mktId }
              options={ markets.filter((mkt) => {
                return mkt.exchId == exchId;
              }).map((mkt) => ({
                label: getMarketPair(mkt).toString(),
                compareValue: mkt.marketId,
                value: {
                  mktId: mkt.marketId,
                  currencyText: getMarketPair(mkt).base,
                  baseCurrencyText: getMarketPair(mkt).quote
                }
              })).sort((a, b) => {
                if (a.label > b.label) return 1;
                if (b.label > a.label) return -1;
                return 0;
              }) }
              onChange={ (e, v) => { 
                onChange(undefined, v); 
            
              } } />
          </div>
        )
      }
      {
      // mktId > 0 && apiVersion == 2 && (
      //   <div className="api-version-note">
      //     <p>
      //       { t(`v2TradingEnabled`) }
      //     </p>
      //   </div>
      // )
      }
      {
        mktId > 0 && tradingEnabled && (
          <div className={ 
            (activeAccounts && activeAccounts.length > 0 
              ? `top-form-section` 
              : ``) + 
          (orderTypeId === 1 ? ` buy-border-color` : ` sell-border-color`) }>
            <div className={ activeAccounts && activeAccounts.length > 0 ? `order-form-select` : `` }>
              {
                activeAccounts && activeAccounts.length > 0 ? (
                // We have an active account, so show the authId select box
              
                  <SelectField
                    searchable={ true }
                    label={ t(`app:account`) }
                    name={ `authId` }
                    value={ authId }
                    options={ [...activeAccounts.map((acct) => ({
                      label: acct.authNickname,
                      value: acct.authId
                    })).sort((a, b) => {
                      if (a.label > b.label) return 1;
                      if (b.label > a.label) return -1;
                      return 0;
                    }), { label: `${t(`user:addNewAccount`)}...`, value: -2 }] }
                    onChange={ (e, v) => onChange(`authId`, v, true) } />

              
                ) : active ? active.exchange.exchCode !== `LSCX` && active.exchange.exchCode !== `CGY` && (
                // No active accounts so show the addNewAccount button
                  <Button
                    type="primary"
                    onClick={ () => { 
                      if (apiVersion == 2 && addNewAccount) {
                        addNewAccount();
                      } else {
                        window.location.assign(`${ window.WWW_URL }/user/api`);
                      }

                      return true;
                    } }>
                    { t(`addAccount`) }
                  </Button>
                ) : null
              }
            </div>

            {
              activeAccounts && activeAccounts.length > 0 ? (
                <div className={ `order-form-select` }>
                  <SelectField
                    searchable={ false }
                    label={ t(`orders:orderType`) }
                    name={ `priceTypeId` }
                    value={ priceTypeId }
                    disabled={ authId === -1 }
                    options={ [
                      { label: t(`limit`), value: 3, name: `LimitOption` },
                      { label: t(`stopLimit`), value: 6, name: `StopLimitOption` },
                    ] }
            
                    onChange={ (e, v) => onChange(`priceTypeId`, v, true) } />
                </div>
              ) : null
            }
            {
              activeAccounts && activeAccounts.length > 0 ? (
                <a
                  onClick={ () => { 
                    if (parseFloat(orderQty) > 0) {
                      resetOrderForm();
                      if (lastPrice) {
                        onChange(undefined, {
                          limitPrice: lastPrice,
                          stopPrice: lastPrice                        
                        });
                      }
                    }
                    return true;
                  } }
                  className={ parseFloat(orderQty) > 0 ? `eraser-active` : `eraser-disabled` }>

                  { Trash2(`reset-order-form`) }
                </a>
              ) : null
            }
            {
              activeAccounts && activeAccounts.length > 0 ? (
                <a
                  onClick={ () => { 
                    refreshBalance();
                    return true;
                  } }
                  className={ 
                    `refresh ${ refreshState } ${ refreshEnabled() ? `enabled` : `` } ` +
                    `${ accountStalenessClass() }`
                  }>
                  
                  <Refresh/>
                </a>
              ) : null
            }
            
          </div>
        )
      }
      {
        authId > 0 && tradingEnabled && balanceData && (
          <div className="available-balance single-form-balances-wrapper">
            <div
              className={ `single-form-balances balances-left ` + (orderTypeId === 1 ? `active` : ``) }
              onClick={ () => {
                onChange(`orderTypeId`, 1);
                setTimeout(() => onChange(`orderQty`, 0), 10);
              } }>
              <div className="label">
                { t(`availableBalance`) + ` (` + t(`app:buy`) + `)` }
              </div>
              { 
                balanceData.buy ? (
                  <span
                    className={ orderTypeId === 1 ? `active avail-bal` : `avail-bal` } 
                    onClick={ () => {
                      onChange(`orderTypeId`, 1);
                      setTimeout(() => onChange(
                        `orderQty`, 
                        toFixedDecimals(
                          balanceData.buy.balanceAmountAvailable, 
                          false, 
                          `balance`, 
                          active ? active.market : undefined
                        )), 
                      100);
                    } }>
                    <span 
                      dangerouslySetInnerHTML={ 
                        toFixedDecimalsHTML(
                          balanceData.buy.balanceAmountAvailable, 
                          false, 
                          `balance`, 
                          active ? active.market : undefined
                        ) 
                      } />
                    { ` ` }
                    { baseCurrencyText }
                  </span>
                ) : (
                  <span
                    className={ orderTypeId === 1 ? `active avail-bal` : `avail-bal` } 
                    onClick={ () => {
                      onChange(`orderTypeId`, 1);
                      setTimeout(() => onChange(`orderQty`, 0), 100);
                    } }>
                    <span 
                      dangerouslySetInnerHTML={ 
                        toFixedDecimalsHTML(
                          0, 
                          false, 
                          `balance`, 
                          active ? active.market : undefined
                        ) 
                      } />
                    { ` ` }
                    { baseCurrencyText }
                  </span>
                )
              }
            </div>
            { /* <div className="single-form-balances balances-center">
            <div className="label">
              { t(`user:active`) + ` ` + t(`app:market`) }
            </div>
            <span className="market-label">
              { active ? (active.exchange.exchCode + ` ` + active.market.displayName) : null }
            </span>
          </div> */ }
            <div
              className={ `single-form-balances balances-right ` + (orderTypeId === 1 ? `` : `active`) }
              onClick={ () => {
                onChange(`orderTypeId`, 2);
                setTimeout(() => onChange(`orderQty`, 0), 10);
              } }>
              <div className="label">
                { t(`availableBalance`) + ` (` + t(`app:sell`) + `)` }
              </div>
              { 
                balanceData.sell ? (
                  <span
                    className={ orderTypeId === 2 ? `active avail-bal` : `avail-bal` } 
                    onClick={ () => {
                      onChange(`orderTypeId`, 2);
                      setTimeout(() => onChange(
                        `orderQty`, 
                        toFixedDecimals(
                          balanceData.sell.balanceAmountAvailable, 
                          true, 
                          `balance`, 
                          active ? active.market : undefined
                        ) 
                      ), 100);
                    } }>
                    <span 
                      dangerouslySetInnerHTML={ 
                        toFixedDecimalsHTML(
                          balanceData.sell.balanceAmountAvailable, 
                          true, 
                          `balance`, 
                          active ? active.market : undefined
                        ) 
                      } />
                    { ` ` }
                    { currencyText }
                  </span>
                ) : (
                  <span
                    className={ orderTypeId === 2 ? `active avail-bal` : `avail-bal` } 
                    onClick={ () => {
                      onChange(`orderTypeId`, 2);
                      setTimeout(() => onChange(`orderQty`, 0), 100);
                    } }>
                    <span 
                      dangerouslySetInnerHTML={ 
                        toFixedDecimalsHTML(
                          0, 
                          true, 
                          `balance`, 
                          active ? active.market : undefined
                        ) 
                      } />
                    { ` ` }
                    { currencyText }
                  </span>
                )
              }
            </div>
          </div>
        )
      }
      { /* this is the old pilltoggle BUY / SELL buttons
      ///// singleForm start
      mktId > 0 && tradingEnabled && (
        <div>
          <PillToggle
            value={ orderTypeText }
            disabled={ authId === -1 }
            options={ [
              {
                label: t(`app:buy`),
                compareValue: `Buy`,
                value: {
                  orderTypeText: `Buy`,
                  orderTypeId: 1
                }
              },
              {
                label: t(`app:sell`),
                compareValue: `Sell`,
                value: {
                  orderTypeText: `Sell`,
                  orderTypeId: 2
                }
              }
            ] }
            onChange={ (e, v) => onChange(undefined, v, false, () => {
              onChange(`limitPrice`, limitPrice);
            }) } />
        </div>
      )
      */
      }
      {
        mktId > 0 && tradingEnabled &&
      (
        <div className={ `single-form-wrapper ` + (authId === -1 ? `disabled` : ``)  }>
          { /* inputs-left start */ }
          { 
            mktId > 0 && tradingEnabled && orderTypeId === 2 && updateOrderFormType ? (
              <div
                className={ `collapse-section collapse-is-sell` }
                onClick={ () => updateOrderFormType && updateOrderFormType(`dual`) }>
                  
                <div className={ `collapse-inner-wrapper` }>
                  {
                    CollapseRight(`collapseIsSell`)
                  }
                </div>
              </div>
            ) : null
          }
          
          <div className={ `single-form-inputs` } >
            {
              priceTypeId === 6 && (
                <div>
                  <div className="addFlex flex-center">
                    <div className={ `order-type-hint-text` }>
                        When the 
                      { ` ` }
                      <b>
Price
                      </b>
                      { ` ` }
of 
                      { ` ` }
                      <b>
                        <div
                          className={ `stop-market-selector` + (apiVersion == 2 ? ` clickable` : ``) }
                          onClick={ apiVersion == 2 ? showMarketSelectorModal.bind(this) : null }>
                          {
                                
                            stopMarketName + ` ▾`
                          }
                        </div>
                        {
                          marketSelectorModalOpen && (
                            <MarketSelectorModal
                              showLSCX={ false }
                              exchanges={ exchanges }
                              markets={ markets }
                              accounts={ [] }
                              panel={ { settingsJson: JSON.stringify({
                                exchange: stopMarketName.split(`:`)[0],
                                market: stopMarketName.split(`:`)[1],
                                panelId: 1234
                              }) } }
                              exit={ hideMarketSelectorModal.bind(this) }
                              setMarket={ setStopMarket.bind(this) } />
                          )
                        }
                      </b>
                      { ` ` }
reaches
                    </div>
                  </div>
                  <div className="addFlex">
                    <sub />
                    <div className="inputs-label bold">
                      { t(`stopPrice`) }
                    </div>
                    <div className="inputs-container">
                      <PresetTextField
                        selectOnLeft={ true }
                        label={ `` }
                        disabled={ authId === -1 }
                        name={ `stopPrice` }
                        value={ stopPrice }
                        options={ (active && active.market.exchmktId === stopMarketId) ? options : [] }
                        errorText={ stopPriceError }
                        optionsLabel={ `$` }
                        compact={ true }
                        onChange={ (e) => onChange(`stopPrice`, e) } 
                        commaSeparator/>
                    </div>              
                  </div>
                  <div className="addFlex flex-center">
                    <div className={ `order-type-hint-text` }>
                          
Place the following 
                      { ` ` }
                      <b>
Limit Order
                      </b>
                      { ` ` }
on
                      { ` ` }
                      <b>
                        {
                          active && active.exchange.exchCode
                        }
                            :
                        {
                          active && active.market.displayName
                        }
                      </b>
:
                    </div>
                  </div>
                </div>
              )
            }
    
            <div className="addFlex">
              <div className="inputs-label">
                { orderTypeId == 1 ?
                  `${ baseCurrencyText } ${ t(`toSpend`) }` :
                  `${ currencyText } ${ t(`toSell`) }` }
              </div>
              <div className="inputs-container">
                <PresetTextField
                  selectOnLeft={ true }
                  label={ `` }
                  disabled={ authId === -1 }
                  name={ `quantity` }
                  value={ orderQty }
                  options={ balanceOptions }
                  errorText={ orderQuantityError }
                  optionsLabel={ `%` }
                  compact={ true }
                  onChange={ (e) => onChange(`orderQty`, e) } />
              </div>
            </div>
    
            <div>
              {
                options && options.length > 0 ? (
                  <div className="addFlex">
                    <div className="inputs-label bold">
                      { `${ t(`pricePer`) } ${ currencyText }` }
                    </div>
                    <div className="inputs-container">
                      <PresetTextField
                        selectOnLeft={ true }
                        disabled={ authId === -1 }
                        name={ `limitPrice` }
                        value={ limitPrice }
                        options={ options }
                        errorText={ limitPriceError }
                        optionsLabel={ `$` }
                        compact={ true }
                        onChange={ (v) => onChange(`limitPrice`, v) } 
                        commaSeparator/>
                    </div>
                  </div>
                ) : (
                  <div className="addFlex">
                    <div className="inputs-label bold">
                      { `${ t(`pricePer`) } ${ currencyText }` }
                    </div>
                    <div className="inputs-container">
                      <TextField
                        disabled={ authId === -1 }
                        name={ `limitPrice` }
                        value={ limitPrice }
                        label={ `` }
                        errorText={ limitPriceError }
                        compact={ true }
                        onChange={ (v) => onChange(`limitPrice`, v.target.value) } />
                    </div>
                  </div>
                )
              }
            </div>
      
            <div className="addFlex">
              <div className="inputs-label">
                { orderTypeId == 1 ?
                  `${ currencyText } ${ t(`toBuy`) }` :
                  `${ baseCurrencyText } ${ t(`toReceive`) }` }
              </div>
              <div className="inputs-container add-padding">
                <TextField
                  disabled={ authId === -1 }
                  name={ `orderTotal` }
                  value={ orderTotal }
                  label={ `` }
                  errorText={ orderTotalError }
                  compact={ true } 
                  onChange={ (e) => onChange(`orderTotal`, e.target.value) } />
              </div>
            </div>
        
          </div>
          { 
            mktId > 0 && tradingEnabled && orderTypeId === 1 && updateOrderFormType ? (
              <div
                className={ `collapse-section collapse-is-buy` }
                onClick={ () => updateOrderFormType && updateOrderFormType(`dual`) }>
                  
                <div className={ `collapse-inner-wrapper` }>
                  {
                    CollapseLeft(`collapseIsBuy`)
                  }
                </div>
              </div>
            ) : null
          }
        </div>
      )
      
      }
      {
        mktId > 0 && tradingEnabled && (
          <div className={ 
            `single-form-wrapper submit-wrapper ` + 
          (activeAccounts && activeAccounts.length > 0 ? `` : `dimmed`) }>
          
            <div className="submit">
              <Button
                type={ orderTypeId == 1 ? `confirm` : `cancel` }
                disabled={ (authId === -1 || parseFloat(orderQty) <= 0 || parseFloat(orderTotal) <= 0) }
                onClick={ (e) => onSubmit(e) }>
                {
                  active && parseFloat(orderTotal) > 0 ? (
                    orderTypeId == 1 ?
                      `${ t(`app:buy`) } (${ toFixedDecimals(orderTotal,
                        true, `quantity`, active.market) } ${ currencyText })` :
                      `${ t(`app:sell`) } (${ toFixedDecimals(orderQty,
                        false, `quantity`, active.market) } ${ currencyText })`
                  ) : (
                    orderTypeId == 1 ?
                      `${ t(`app:buy`) } ${ currencyText }` :
                      `${ t(`app:sell`) } ${ currencyText }`
                  )
                }
              </Button>
            </div>
      
          </div>
        )
      }
    
    
   
      {
      /* grey section underneath the order form */
        mktId > 0 && tradingEnabled && (
          <div className={ 
            `single-bottom-form-section` + 
          (orderTypeId === 1 ? ` buy-border-color ` : ` sell-border-color `) + 
          (activeAccounts && activeAccounts.length > 0 ? `` : `dimmed`) }>
            <div className={ `order-summary-text` }>
              {
                active ? (
                  orderTypeId == 1 ?
                    `${ t(`app:buy`) } ${ parseFloat(orderTotal) > 0 ? orderTotal : `` } ${ currencyText } ${ t(`with`) }` +
                ` ${ parseFloat(orderQty) > 0 ? orderQty : `` } ${ baseCurrencyText }` :
                    `${ t(`app:sell`) } ${ parseFloat(orderQty) > 0 ? orderQty : `` } ${ currencyText } ${ t(`for`) }` +
                ` ${ parseFloat(orderTotal) > 0 ? orderTotal : `` } ${ baseCurrencyText }`
                ) : (
                  orderTypeId == 1 ?
                    `${ t(`app:buy`) } ${ parseFloat(orderTotal) > 0 ? orderTotal : `` } ${ currencyText } ${ t(`with`) }` +
                ` ${ parseFloat(orderQty) > 0 ? orderQty : `` } ${ baseCurrencyText }` :
                    `${ t(`app:sell`) } ${ parseFloat(orderQty) > 0 ? orderQty : `` } ${ currencyText } ${ t(`for`) }` +
                ` ${ parseFloat(orderTotal) > 0 ? orderTotal : `` } ${ baseCurrencyText }`
                )
              }
            </div>
            <div className={ `auto-confirm-wrapper` }>
              <Checkbox
                name={ `autoConfirm` }
                label={ t(`app:autoConfirm`) }
                value={ autoConfirm } 
                onChange={ (e) => {
                  onChange(`autoConfirm`, e.target.checked);
                } }/>
            </div>
          </div>
        )
      }
      { ///// dualForm end
        mktId > 0 && !tradingEnabled && (
          <div className="trading-disabled">
            <p>
              { t(`tradingDisabled`) }
            </p>
          </div>
        )
      }
    </div>
  );};

export { OrderForm as PureOrderForm };
export default translate(`orders`)(OrderForm);
