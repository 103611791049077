// @flow
'use strict';

import React from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import SingleOrderForm from './SingleOrderForm.jsx';
import DualOrderForm from './DualOrderForm.jsx';
import NewAccountModal from '../user/NewAccountModal.jsx';
import ConfirmOrderModal from './ConfirmOrderModal.jsx';
import LSCXButton from '../markets/LSCXButton.jsx';
import BakktButton from '../user/Bakkt/BakktButton.jsx';
import { AllInCostCalculator } from './AllInCostCalculator.jsx';
import { setV1Order, setV2LimitOrder, setV2StopLimitOrder } from '../../helpers/api/OrderApi';
import { getTickerData } from '../../helpers/api/ExchangeApi';
import { getExchangeTradeApiVersion } from '../../helpers/ExchangeTradingHelper';
import { getMarketPair } from '../../helpers/MarketPairHelper';
import { toFixedDecimals, countDecimals } from '../../helpers/NumberHelper.js';
import { forceUTCParsing } from '../../helpers/DateHelper.js';
import type { Exchange } from '../../types/Exchange.js';
import type { Chain } from '../../types/Chain.js';
import type { Market } from '../../types/Market.js';
import type { Balance } from '../../types/Balance.js';
import type { Account } from '../../types/Account';
import type { MarketOrder } from '../../types/MarketOrder.js';
import Stop from '../../svgs/Stop.jsx';
import {
  emitEvent,
  listenForEvent,
  removeEventListener,
  MARKET_BUY_MODE_ACTIVATED,
  MARKET_SELL_MODE_ACTIVATED,
  MARKET_BUY_MODE_DEACTIVATED,
  MARKET_SELL_MODE_DEACTIVATED,
  DELETE_BUY_NODE,
  DELETE_SELL_NODE,
  DELETE_SELL_STOP_NODE,
  DELETE_BUY_STOP_NODE,
  SWITCH_TO_SELL_MODE,
  SWITCH_TO_BUY_MODE,
  SWITCH_TO_SELL_MODE_WITH_PRICE,
  SWITCH_TO_BUY_MODE_WITH_PRICE,
  SWITCH_TO_BUY_MODE_WITH_STOP_PRICE,
  SWITCH_TO_SELL_MODE_WITH_STOP_PRICE,
  BUY_MODE_REQUESTED,
  BUY_CONFIRM_REQUESTED,
  SELL_CONFIRM_REQUESTED,
  PRICE_CHANGE_REQUESTED,
  STOP_PRICE_CHANGE_REQUESTED,
  MARKET_SELL_MODE_ACTIVATION_ACKNOWLEDGED,
  MARKET_BUY_MODE_ACTIVATION_ACKNOWLEDGED,
  ORDER_ADDED,
  ORDER_ADDED_FAILED,
  PENDING_ORDER_ADDED,
  REFRESH_BALANCE,
  REFRESH_ACCOUNTS,
  NOTIFICATION,
  UPDATE_CURRENT_AUTHID
} from '../../helpers/EventHelper.js';
import { updateNewOrderAutoConfirm } from '../../actions/app/updateNewOrderAutoConfirm.js';

type Props = {
  t: any,
  markets: Array<Market>,
  exchanges: Array<Exchange>,
  currencies: Array<Chain>,
  active: {
    exchange: Exchange,
    market: Market
  },
  lastPrice?: number,
  marketLabel?: string,
  options?: Array<{
    label: string,
    value: any,
    icon?: any,
    compareValue?: any
  }>,
  balances: Array<Balance>,
  accounts: Array<Account>,
  currentAuthId?: number,
  setCurrentAccount?: (authId: number) => void,
  isDetached?: boolean,
  redirectToAccounts?: () => void,
  detachedOnChange?: (value: any) => void,
  updateBOHTab: (tab: number) => void,
  orderFormType: string,
  updateOrderFormType?: (orderFormType: string) => void,
  bestBid: number,
  bestAsk: number,
  buys: Array<MarketOrder>,
  sells: Array<MarketOrder>,
  newOrderAutoConfirm: boolean,
  updateNewOrderAutoConfirm: (autoConfirm: boolean) => void,   
};

type State = {
  mktId: number,
  exchId: number,
  authId: number,
  limitPrice?: string,
  stopMarketId?: number,
  stopMarketName?: string,
  stopPricePrecision?: number,
  stopPrice?: string,
  orderQty: string,
  orderTotal: string,
  orderTypeId: number,
  orderTypeText: string,
  orderStatusId: number,
  currencyText: string,
  priceTypeId: number,
  baseCurrencyText: string,
  refreshState: string,
  limitPriceError?: string,
  stopPriceError?: string,
  orderQuantityError?: string,
  priceTypeError?: string,
  orderTotalError?: string,
  newAccountModalShown?: boolean,
  confirmOrderModalOpen: boolean,
  marketSelectorModalOpen: boolean,
  inputDirty: boolean,
  allInCostCalculatorOpen: boolean,
};

type V1_Order = {
  mkt_id: number,
  auth_id: number,
  limit_price: number,
  stop_price: number,
  order_quantity: number,
  order_total: number,
  order_operator: string,
  order_type_id: number,
  order_type_text: string,
  currency_text: string,
  price_type_id: number,
  base_currency_text: string,
  exch_id: number
};

type V2_Order = {
  ExchMktId: number,
  side: string,
  price: number,
  quantity: number,
  conditionalOperator?: string,
  priceType: number,
  TriggerExchMktId?: number,
  TriggerPrice?: number
};

const calculatorEneabledExchanges = [`FTXUS` , `KRKN`  , `GMNI` , `GDAX`, `LSCX` ];

class NewOrder extends React.PureComponent<Props, State> {
  tvMsgHandler: (e: any) => void;
  subscribeEvents: Array<string> = [
    SWITCH_TO_SELL_MODE,
    SWITCH_TO_BUY_MODE,
    SWITCH_TO_BUY_MODE_WITH_PRICE,
    SWITCH_TO_SELL_MODE_WITH_PRICE,
    SWITCH_TO_BUY_MODE_WITH_STOP_PRICE,
    SWITCH_TO_SELL_MODE_WITH_STOP_PRICE,
    BUY_MODE_REQUESTED,
    BUY_CONFIRM_REQUESTED,
    SELL_CONFIRM_REQUESTED,
    PRICE_CHANGE_REQUESTED,
    STOP_PRICE_CHANGE_REQUESTED,
    MARKET_SELL_MODE_ACTIVATION_ACKNOWLEDGED,
    MARKET_BUY_MODE_ACTIVATION_ACKNOWLEDGED,
  ];
  
  constructor(props: Props) {
    super(props);

    this.state = this.getInitialState();

    this.tvMsgHandler = this.handleTVMessage.bind(this);
  }

  getInitialState(props: Props = this.props) {
    return {
      ...this.getStateFromProps(true, props),
      orderQty: `0`,
      orderTotal: `0`,
      orderTypeId: 1,
      orderTypeText: `Buy`,
      orderStatusId: 0,
      priceTypeId: 3,
      refreshState: ``,
      limitPriceError: ``,
      stopPriceError: ``,
      orderQuantityError: ``,
      priceTypeError: ``,
      orderTotalError: ``,
      newAccountModalShown: false,
      confirmOrderModalOpen: false,
      marketSelectorModalOpen: false,
      inputDirty: false,
      allInCostCalculatorOpen: false,
    };
  }

  componentDidMount() {
    this.sendToTV();
    this.subscribeEvents.forEach((event) => {
      listenForEvent(event, this.tvMsgHandler);
    });
    this.setStopMarketIfLSCX();
  }

  setStopMarketIfLSCX() {
    const isLSCXActiveMarket = this.props.active.market.exchCode === `LSCX`;
    if (isLSCXActiveMarket) {
      // Search for GDAX
      const mktName = this.props.active.market.displayName;
      const market = this.props.markets.find((mkt) => mkt.exchCode === `GDAX` && mkt.marketName === mktName);
      this.setState({ 
        stopMarketId: market?.exchmktId,
        stopMarketName: `GDAX:` + this.props.active.market.displayName 
      });
    } else {
      // don't allow market orders on non-LSCX exchanges
      if (this.state.priceTypeId === 5) {
        this.setState({
          priceTypeId: 3
        });
      }
      this.setState({ 
        stopMarketId: this.props.active.market.exchmktId,
        stopMarketName: this.props.active.market.exchCode + `:` + this.props.active.market.displayName 
      });
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.orderFormType !== this.props.orderFormType) {
      this.setState({ orderQty: `0` });
    }
    if(prevProps.active.market !== this.props.active.market) {
      this.setState({ inputDirty: false });
      // don't allow market orders on non-LSCX exchanges
      if (this.props.active.market.exchCode !== `LSCX` && this.state.priceTypeId === 5) {
        this.setState({
          priceTypeId: 3
        });
      }
    }

    if (this.state.priceTypeId === 5 && ((prevProps.bestBid !== this.props.bestBid) || (prevProps.bestAsk !== this.props.bestAsk))) {
      if (this.state.orderTypeId == 1) {
        // market buy
        this.handleInputChange(`limitPrice`, this.props.bestAsk);
      }else if (this.state.orderTypeId == 2) {
        // market sell
        this.handleInputChange(`limitPrice`, this.props.bestBid);
      }
    }
    
  }

  componentWillUnmount() {
    this.subscribeEvents.forEach((event) => {
      removeEventListener(event, this.tvMsgHandler);
    });
  }

  sendToTV(props: Props = this.props) {
    if (!this.getApiVersion() || this.getActiveAccounts(props, this.state).length == 0) {
      emitEvent(MARKET_BUY_MODE_DEACTIVATED);
      emitEvent(MARKET_SELL_MODE_DEACTIVATED);
      return;
    }

    if (!props.active) return;
    this.updateTV(props);
  }

  updateTV(props: Props = this.props, state: State = this.state) {
    if (!this.getApiVersion() || this.getActiveAccounts(props, this.state).length == 0 || this.state.orderStatusId === 1) {
      emitEvent(MARKET_BUY_MODE_DEACTIVATED);
      emitEvent(MARKET_SELL_MODE_DEACTIVATED);
      return;
    }
    const { active } = props;

    if (!active || !active.market) return;
    emitEvent(state.orderTypeId === 1 ? MARKET_SELL_MODE_DEACTIVATED : MARKET_BUY_MODE_DEACTIVATED);
    
    // if (!(parseFloat(state.orderQty) > 0 && parseFloat(state.limitPrice) > 0)) return;
    const { market } = active;

    emitEvent(state.orderTypeId == 1 ? MARKET_BUY_MODE_ACTIVATED : MARKET_SELL_MODE_ACTIVATED, {
      price: state.limitPrice,
      stopPrice: state.stopPrice,
      orderQty: state.orderQty,
      orderTotal: state.orderTotal,
      stopEnabled: parseFloat(state.orderQty) > 0 && state.priceTypeId == 6,
      quantity: `(Limit) ${
        state.orderTypeId == 1 ? this.props.t(`spend`) : this.props.t(`receive`) } ${ state.orderTypeId == 1 ?
        state.orderQty :
        state.orderTotal } ${
        getMarketPair(market).quote
      }`,
      total: `${ state.orderTypeText } ${ state.orderTypeId == 1 ?
        state.orderTotal :
        state.orderQty } ${
        getMarketPair(market).base
      }`,
      priceTypeId: state.priceTypeId,
      exchmktId: market.exchmktId
    });
  }

  handleTVMessage(e: any) {
    if (!e) return;
    if (e.type
      && [SWITCH_TO_BUY_MODE, SWITCH_TO_SELL_MODE].indexOf(e.type) >= 0
      && !this.getApiVersion()) return;
    switch(e.type) {
    case SWITCH_TO_SELL_MODE:
      this.setState({
        orderTypeText: `Sell`,
        orderTypeId: 2
      }, () => this.updateTV());
      if (this.props.orderFormType == `single`) { 
        this.handleInputChange(`orderQty`, 0);
        setTimeout(() => emitEvent(DELETE_BUY_STOP_NODE), 50);
        setTimeout(() => emitEvent(DELETE_BUY_NODE), 50);
      }
      break;
    case SWITCH_TO_BUY_MODE:
      this.setState({
        orderTypeText: `Buy`,
        orderTypeId: 1
      }, () => this.updateTV());
      if (this.props.orderFormType == `single`) { 
        this.handleInputChange(`orderQty`, 0);
        setTimeout(() => emitEvent(DELETE_SELL_STOP_NODE), 50);
        setTimeout(() => emitEvent(DELETE_SELL_NODE), 50);
      }
      break;
    case SWITCH_TO_SELL_MODE_WITH_PRICE:
      if (this.props.orderFormType == `single`) { 
        this.setState({
          orderTypeText: `Sell`,
          orderTypeId: 2,
        });
        this.handleInputChange(`limitPrice`, toFixedDecimals(e.detail, true, `price`, this.props.active.market), true);
        setTimeout(() => emitEvent(DELETE_BUY_STOP_NODE), 50);
        setTimeout(() => emitEvent(DELETE_BUY_NODE), 50);
      }
        
      break;
    case SWITCH_TO_BUY_MODE_WITH_PRICE:
      if (this.props.orderFormType == `single`) {
        this.setState({
          orderTypeText: `Buy`,
          orderTypeId: 1,
        });
        this.handleInputChange(`limitPrice`, toFixedDecimals(e.detail, true, `price`, this.props.active.market), true);
        setTimeout(() => emitEvent(DELETE_SELL_STOP_NODE), 50);
        setTimeout(() => emitEvent(DELETE_SELL_NODE), 50);
      }
      break;
    case SWITCH_TO_BUY_MODE_WITH_STOP_PRICE:
      if (this.props.orderFormType == `single`) {
        this.setState({
          orderTypeText: `Buy`,
          orderTypeId: 1,
        });
        this.handleInputChange(`stopPrice`, toFixedDecimals(e.detail, true, `price`, this.props.active.market), true);
        setTimeout(() => emitEvent(DELETE_SELL_STOP_NODE), 50);
        setTimeout(() => emitEvent(DELETE_SELL_NODE), 50);
      }
      break;
    case SWITCH_TO_SELL_MODE_WITH_STOP_PRICE:
      if (this.props.orderFormType == `single`) {
        this.setState({
          orderTypeText: `Sell`,
          orderTypeId: 2,
        });
        this.handleInputChange(`stopPrice`, toFixedDecimals(e.detail, true, `price`, this.props.active.market), true);
        setTimeout(() => emitEvent(DELETE_BUY_STOP_NODE), 50);
        setTimeout(() => emitEvent(DELETE_BUY_NODE), 50);
      }
      break;
    case BUY_CONFIRM_REQUESTED:
    case SELL_CONFIRM_REQUESTED:
      this.confirmOrder(null);
      break;
    case MARKET_BUY_MODE_ACTIVATION_ACKNOWLEDGED:
      if (this.props.orderFormType == `single`) { 
        setTimeout(() => emitEvent(DELETE_SELL_STOP_NODE), 50); 
        setTimeout(() => emitEvent(DELETE_SELL_NODE), 50); 
      }
      break;
    case MARKET_SELL_MODE_ACTIVATION_ACKNOWLEDGED:
      if (this.props.orderFormType == `single`) { 
        setTimeout(() => emitEvent(DELETE_BUY_STOP_NODE), 50);
        setTimeout(() => emitEvent(DELETE_BUY_NODE), 50); 
      }
      break;
    case PRICE_CHANGE_REQUESTED:
      this.handleInputChange(`limitPrice`, toFixedDecimals(e.detail, true, `price`, this.props.active.market), true);
      break;
    case STOP_PRICE_CHANGE_REQUESTED:
      this.handleInputChange(`stopPrice`, toFixedDecimals(e.detail, true, `price`, this.props.active.market), true);
      break;
    }
  }

  getStateFromProps(setPrice: boolean = true, props: Props = this.props) {
    let state: {
      mktId: number,
      exchId: number,
      currencyText: string,
      baseCurrencyText: string,
      limitPrice?: string,
      stopPrice?: string,
      orderQty?: string,
      orderTotal?: string,
      authId: number
    } = {
      mktId: (props.active && props.active.market) ?
        props.active.market.marketId :
        this.state ?
          this.state.mktId :
          -1,
      exchId: (props.active && props.active.exchange) ?
        props.active.exchange.exchId :
        this.state ?
          this.state.exchId :
          -1,
      currencyText: (props.active && props.active.market) ?
        getMarketPair(props.active.market).base :
        this.state ?
          this.state.currencyText :
          ``,
      baseCurrencyText: (props.active && props.active.market) ?
        getMarketPair(props.active.market).quote :
        this.state ?
          this.state.baseCurrencyText :
          ``,
      authId: props.currentAuthId ? props.currentAuthId : -1
    };

    if (setPrice) {
      const active = props.active;
      if (active && active.market) {
        let price = toFixedDecimals(props.lastPrice || 0, true, `price`, active.market);
        state.limitPrice = props.lastPrice ? price : `0`;
        state.stopPrice = props.lastPrice ? price : `0`;
        state.orderQty = `0`;
        state.orderTotal = `0`;
      } else {
        state.limitPrice = props.lastPrice ? props.lastPrice.toString() : `0`;
        state.stopPrice = props.lastPrice ? props.lastPrice.toString() : `0`;
        state.orderQty = `0`;
        state.orderTotal = `0`;
      }
    }

    if (this.state && state.exchId !== this.state.exchId) {
      state.authId = -1;
    }

    const accounts = this.getActiveAccounts(props, state);

    if (accounts.length == 1) {
      if (this.state && this.state.authId !== accounts[0].authId) {
        state.authId = accounts[0].authId;
        emitEvent(UPDATE_CURRENT_AUTHID, accounts[0].authId);
      }
    } else if (accounts.length === 0) {
      state.authId = -1;
    } else if (accounts.length > 0) {
      const exchId = (props.active && props.active.exchange) ? props.active.exchange.exchId : this.state ? this.state.exchId : -1;
      const savedAuthsIds = JSON.parse(localStorage.getItem(`selectedAuthIds`) || `[]`);
      const potentialExchSavedAuthsId = savedAuthsIds.filter((e) => exchId === e.exchangeId);
      if (potentialExchSavedAuthsId.length > 0) {
        state.authId = potentialExchSavedAuthsId[0].selectedAuthId;
      }
    }
    return state;
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (JSON.stringify(this.props.active) !== JSON.stringify(nextProps.active) || this.props.lastPrice == 0) {
      this.setState(this.getStateFromProps(true, nextProps), () => {
        if (this.props.active.market) {
          this.setStopMarket(nextProps.active.exchange.exchCode, nextProps.active.market.marketName);
        }
        this.sendToTV(nextProps);
      });
    } else {
      if (this.props.isDetached && this.props.lastPrice !== nextProps.lastPrice) { 
        this.setState(this.getStateFromProps(true, nextProps));
      } else {
        this.setState(this.getStateFromProps(false, nextProps));
      }
    }
  }

  getTickerData(exch_id: number, mkt_id: number) {
    const exchange = this.props.exchanges.find((exch) => exch.exchId === exch_id);
    const market = this.props.markets.find((mkt) => mkt.exchId === exch_id && mkt.marketId === mkt_id);
    if (!market || !exchange) return;
    
    getTickerData(exchange.exchCode, market.marketName, (data) => {
      if (data.success) {
        this.setState({
          limitPrice: toFixedDecimals(data.result.last, false, `price`, market),
        });
      }
    });
  }

  setStopMarket(exchCode: string, mktName: string) {
    const exchCodeToFilter = exchCode === `LSCX` ? `GDAX` : exchCode;
    let market = this.props.markets.find((mkt) => mkt.exchCode === exchCodeToFilter && mkt.marketName === mktName);
    if (!market) return;

    getTickerData(market.exchCode, market.marketName, (data) => {
      if (data.success) {
        
        if (parseFloat(data.result.last) > 0) {
          this.setState({
            stopMarketId: market.exchmktId,
            stopMarketName: market.exchCode + `:` + market.displayName,
            stopPricePrecision: market.basePricePrecision,
            stopPrice: toFixedDecimals(data.result.last, false, `price`, market),
            marketSelectorModalOpen: false
          });
        }
      }
    });

    
    return false;
  }

  showMarketSelectorModal() {
    this.setState({
      marketSelectorModalOpen: true 
    });
  }

  hideMarketSelectorModal() {
    this.setState({
      marketSelectorModalOpen: false 
    });
  }

  UNSAFE_componentWillUpdate(nextProps: Props, nextState: State) {
    if (this.state.authId === nextState.authId) return;

    const accounts = this.getActiveAccounts(nextProps, nextState);
    let authId: number = -1;
    
    if (accounts.length == 1) {
      authId = accounts[0].authId;
    } else {
      authId = nextState.authId;
    }
    

    if (this.props.setCurrentAccount) {
      this.props.setCurrentAccount(authId);
    }
  }

  orderLimitPriceValue(value: any){
    const limitPrice = 
      parseFloat(this.state.limitPrice) == 0 ? 0 : (
        this.state.orderTypeId == 1 
          ? toFixedDecimals(parseFloat(value) * parseFloat(this.state.limitPrice), false, `quantity`, this.props.active.market, true)
          : toFixedDecimals(parseFloat(value) / parseFloat(this.state.limitPrice), true, `quantity`, this.props.active.market, true)
      );
    
    return parseFloat(limitPrice) > 0 ? limitPrice : 0;   
      
  }

  orderQuantityValue(value: any){
    const limitPrice = 
      parseFloat(this.state.limitPrice) == 0 ? 0 : (
        this.state.orderTypeId == 1 
          ? toFixedDecimals(parseFloat(value) / parseFloat(this.state.limitPrice), true, `quantity`, this.props.active.market, true)
          : toFixedDecimals(parseFloat(value) * parseFloat(this.state.limitPrice), false, `quantity`, this.props.active.market, true)
      );
    
    return parseFloat(limitPrice) > 0 ? limitPrice : 0;   
  }

  handleInputChange(inputKey?: string, value: any, forceNumber?: boolean, callback?: () => void) {
    let stateVals: any;
    let saveAuthId: any;
    let prevSelectedAuthIds: any;
    let newSelectedAuthIds: any;
    switch (inputKey) {
    case `authId`: 
      if (value === -2) {
        this.showNewAccountModal();
        break;
      }

      stateVals = {
        authId: forceNumber ? parseInt(value) : value,
      };

      saveAuthId = {
        exchangeId: this.props.active.exchange.exchId,
        selectedAuthId: value,
      };

      prevSelectedAuthIds = JSON.parse(localStorage.getItem(`selectedAuthIds`) || `[]`);
      newSelectedAuthIds = [...prevSelectedAuthIds];

      if (prevSelectedAuthIds.length < 1) {
        localStorage.setItem(`selectedAuthIds`, JSON.stringify([...newSelectedAuthIds, saveAuthId]));
      }
  
      if (prevSelectedAuthIds.length > 0) {
        const replaceAuthIdIndex = newSelectedAuthIds.findIndex((element) => element.exchangeId == saveAuthId.exchangeId);
        if (replaceAuthIdIndex > -1) {
          newSelectedAuthIds[replaceAuthIdIndex] = saveAuthId;
          localStorage.setItem(`selectedAuthIds`, JSON.stringify(newSelectedAuthIds));
        } else {
          localStorage.setItem(`selectedAuthIds`, JSON.stringify([...newSelectedAuthIds, saveAuthId]));
        }
      }

      emitEvent(UPDATE_CURRENT_AUTHID, stateVals.authId);
    
      break;
    case `autoConfirm`:
      this.props.updateNewOrderAutoConfirm(value);
      break;  

    case `orderTypeId`:
      stateVals = {
        orderTypeId: value,
        orderTypeText: parseFloat(value) == 1 ? `Buy` : `Sell`, 
      };
      break;

    case `priceTypeId`:
      stateVals = {
        priceTypeId: value,
      };
      break;

    case `stopPrice`:
      if (value != `` && !/^\d+(\.\d*)?$/.test(value)) {
        stateVals = { stopPrice: this.state.stopPrice };
        break;
      }

      if (value == ``) {
        value = 0;
      }

      stateVals = {
        stopPrice: value
      };
      break;

    case `orderQty`:
      if (value != `` && !/^\d+(\.\d*)?$/.test(value)) {
        stateVals = { orderQty: this.state.orderQty };
        break;
      }

      if (value.length == 2 && value.includes(`0`) && !this.state.inputDirty && !value.includes(`.`)) {
        const newValue = value.split(`0`).join(``);
        value = newValue;
      }

      this.setState({ inputDirty: true });
      if (value == ``) {
        value = 0;
        this.setState({ inputDirty: false });
      }

      stateVals = {
        orderQty: value,
        orderTotal: this.orderQuantityValue(value),
      };

      if (isNaN(stateVals.orderTotal)) stateVals.orderTotal = 0;
      break;

    case `limitPrice`:
      if (value != `` && !/^\d+(\.\d*)?$/.test(value)) {
        stateVals = { limitPrice: this.state.limitPrice };
        break;
      }

      if (value == ``) {
        value = 0;
      }
      
      if (value == 0) {
        // specific fix for an error thrown when dividing by zero
        stateVals = {
          limitPrice: value,
          orderTotal: value
        };
      }else {
        stateVals = {
          limitPrice: value,
          orderTotal: this.state.orderTypeId == 1 
            ? toFixedDecimals(parseFloat(this.state.orderQty) / parseFloat(value), true, `quantity`, this.props.active.market, true)
            : toFixedDecimals(parseFloat(this.state.orderQty) * parseFloat(value), false, `quantity`, this.props.active.market, true),
        };
      }
      if (isNaN(stateVals.orderTotal)) stateVals.orderTotal = 0;
      break;
            
    case `orderTotal`:
      if (value != `` && !/^\d+(\.\d*)?$/.test(value)) {
        stateVals = { orderTotal: this.state.orderTotal };
        break;
      }

      if (value.length == 2 && value.includes(`0`) && !this.state.inputDirty && !value.includes(`.`)) {
        const newValue = value.split(`0`).join(``);
        value = newValue;
      }

      this.setState({ inputDirty: true });
      if (value == ``) {
        value = 0;
        this.setState({ inputDirty: false });
      }

      stateVals = {
        orderTotal: value,
        orderQty: this.orderLimitPriceValue(value),
      };

      if (isNaN(stateVals.orderQty)) stateVals.orderQty = 0;      
      break;
    case undefined:
      if(value && !value.orderQty) {
        value.orderQty = this.state.orderQty;
      }
      stateVals = value;
      break;
    }

    if (stateVals) {
      this.setState({
        ...stateVals
      }, () => {
        callback && callback();
        this.updateTV();
        this.state.mktId > 0 && this.state.authId > 0 && this.checkOrderFormErrors();
      });
    }

  }

  checkOrderFormErrors() {
    let errorVals: any;
    if (this.state.orderTypeId == 1) {  
      errorVals = {
        // BUY stopPrice Error
        stopPriceError: countDecimals(parseFloat(this.state.stopPrice) > 0 ? this.state.stopPrice : 0) > (this.state.stopPricePrecision ? this.state.stopPricePrecision : this.props.active.market.basePricePrecision) 
          ? `Max of ${ (this.state.stopPricePrecision ? this.state.stopPricePrecision : this.props.active.market.basePricePrecision) } decimals allowed`
          : ``,
        // BUY orderQty Error
        orderQuantityError: countDecimals(parseFloat(this.state.orderQty) > 0 ? this.state.orderQty : 0) > this.props.active.market.quoteQuantityPrecision 
          ? `Max of ${ this.props.active.market.quoteQuantityPrecision } decimals allowed`
          : ``,
        // BUY limitPrice Error
        limitPriceError: countDecimals(parseFloat(this.state.limitPrice) > 0 ? this.state.limitPrice : 0) > this.props.active.market.basePricePrecision 
          ? `Max of ${ this.props.active.market.basePricePrecision } decimals allowed`
          : ``,
        // BUY orderTotal Error
        orderTotalError: countDecimals(parseFloat(this.state.orderTotal) > 0 ? this.state.orderTotal : 0) > this.props.active.market.baseQuantityPrecision 
          ? `Max of ${ this.props.active.market.baseQuantityPrecision } decimals allowed`
          : ``
      };
    }else {
      errorVals = {
        // SELL stopPrice Error
        stopPriceError: countDecimals(parseFloat(this.state.stopPrice) > 0 ? this.state.stopPrice : 0) > (this.state.stopPricePrecision ? this.state.stopPricePrecision : this.props.active.market.basePricePrecision)
          ? `Max of ${ (this.state.stopPricePrecision ? this.state.stopPricePrecision : this.props.active.market.basePricePrecision) } decimals allowed`
          : ``,
        // SELL orderQty Error
        orderQuantityError: countDecimals(parseFloat(this.state.orderQty) > 0 ? this.state.orderQty : 0) > this.props.active.market.baseQuantityPrecision 
          ? `Max of ${ this.props.active.market.baseQuantityPrecision } decimals allowed`
          : ``,
        // SELL limitPrice Error
        limitPriceError: countDecimals(parseFloat(this.state.limitPrice) > 0 ? this.state.limitPrice : 0) > this.props.active.market.basePricePrecision 
          ? `Max of ${ this.props.active.market.basePricePrecision } decimals allowed`
          : ``,
        // SELL orderTotal Error
        orderTotalError: countDecimals(parseFloat(this.state.orderTotal) > 0 ? this.state.orderTotal : 0) > this.props.active.market.quoteQuantityPrecision 
          ? `Max of ${ this.props.active.market.quoteQuantityPrecision } decimals allowed`
          : ``
      };      
    }

    this.setState({
      ...errorVals
    });
  }

  validateV1Order(order: V1_Order) {
    let errors = { };

    if (order.mkt_id < 0 || order.exch_id < 0) {
      return false;
    } 

    if (order.auth_id < 0) {
      return false;
    }

    if (order.stop_price <= 0 || isNaN(order.stop_price)) {
      errors.stopPriceError = this.props.t(`stopPriceError`);
    }

    if (order.limit_price <= 0 || isNaN(order.limit_price)) {
      errors.limitPriceError = this.props.t(`limitPriceError`);
    }

    if (order.order_type_id == 1) {
      if (order.order_quantity <= 0 || isNaN(order.order_quantity)) {
        errors.orderTotalError = this.props.t(`orderTotalError`);
      }

      if (order.order_total <= 0 || isNaN(order.order_total)) {
        errors.orderQuantityError = this.props.t(`orderQuantityError`);
      }
    } else {
      if (order.order_quantity <= 0 || isNaN(order.order_quantity)) {
        errors.orderQuantityError = this.props.t(`orderQuantityError`);
      }

      if (order.order_total <= 0 || isNaN(order.order_total)) {
        errors.orderTotalError = this.props.t(`orderTotalError`);
      }
    }

    if (Object.keys(errors).length > 0) {
      this.setState(errors);
      return false;
    }

    return true;
  }

  validateV2Order(order: V2_Order) {
    let errors = { };

    // if (!order.BaseCurrCode || order.BaseCurrCode.length == 0) {
    //   return false;
    // }

    // if (!order.QuoteCurrCode || order.QuoteCurrCode.length == 0) {
    //   return false;
    // }

    if (order.ExchMktId <= 0) {
      return false;
    }

    if (order.price <= 0) {
      errors.limitPriceError = this.props.t(`limitPriceError`);
    }

    if (order.quantity <= 0 || isNaN(order.quantity)) {
      errors.orderQuantityError = this.props.t(`orderQuantityError`);
    }

    if (parseFloat(order.priceType) <= 0) {
      errors.priceTypeError = this.props.t(`priceTypeError`);
    }

    if (order.priceType === 6) { // STOP (LIMIT)
      if (!order.TriggerPrice || order.TriggerPrice <= 0 || isNaN(order.TriggerPrice || isNaN(order.TriggerExchMktId))) {
        errors.stopPriceError = this.props.t(`stopPriceError`);
      }
    }

    if (Object.keys(errors).length > 0) {
      this.setState(errors);
      return false;
    }

    return true;  
  }

  openConfirmOrderModal() {
    this.setState({
      confirmOrderModalOpen: true,
    });
  }

  closeConfirmOrderModal() {
    this.setState({
      confirmOrderModalOpen: false,
    });
  }

  getOrderText() {
    let account = this.getActiveAccounts().find((a) => a.authId == this.state.authId);

    const isCGYActiveMarket = this.props.active.market.exchCode === `CGY`;

    return account && (
      <div className={ `order-summary-text` }>
        {
              
          this.props.active && this.state.priceTypeId == 6 && (
            <div className={ `order-details-text` }>
              <p>
                <span className="ci-stop">
                  { Stop }
                </span>
                    When 
                { ` ` }
                <b>
                  { this.state.stopMarketName }
                </b>
                { ` ` }
reaches the
                { ` ` }
                <b>
Stop Price
                </b>
                { ` ` }
of
                { ` ` }
                <b>
                  { this.state.stopPrice }
                </b>
,
              </p>
            </div>
          )
        }
        {
          
          this.props.active && (
            this.state.orderTypeId == 1 ? (
              <div className={ `order-details-text` }>
                <p />
                <p>
Place a 
                  { ` ` }
                  <b>
                    { this.state.priceTypeId === 5 ? `Market` : `Limit` }
                  </b>
                  { ` ` }
order on 
                  { ` ` }
                  <b>
                    { this.props.active.exchange.exchCode }
                        &#58;
                    { this.props.active.market.displayName }
                  </b>
                  { ` ` }
using 
                  { ` ` }
                  <b>
                      &quot;
                    { account.authNickname }
                      &quot;
                  </b>
                      &#58;
                </p> 
                <p>
                  <span className="ci-order-limit-buy">
                    { this.state.priceTypeId === 5 ? `M` : `L`  }
                  </span>
                  { ` ` }
                  <b>
                    { this.props.t(`app:buy`) }
                  </b>
                  { ` ` }
                  <b>
                    { this.state.priceTypeId !== 5 && parseFloat(this.state.orderTotal) > 0 ? ` ` + this.state.orderTotal : `` }
                    { this.state.priceTypeId !== 5 && ` ` }
                    { this.state.currencyText }
                  </b>
                  { ` ` }
                  { this.props.t(`with`) } 
                  { ` ` }
                  <b>
                    { parseFloat(this.state.orderQty) > 0 ? ` ` + this.state.orderQty : `` } 
                    { ` ` }
                    { this.state.baseCurrencyText }
                  </b> 
                  { ` at a price of ` }
                  <b>
                    { this.state.priceTypeId === 5 ? `~` + this.props.bestAsk : this.state.limitPrice } 
                    { ` ` }
                    { this.state.baseCurrencyText }
                  </b>
                </p>
              </div>) 
              :
              (
                <div className={ `order-details-text` }>
                  <p />
                  <p>
  Place a 
                    { ` ` }
                    <b>
  Limit
                    </b>
                    { ` ` }
  order on 
                    { ` ` }
                    <b>
                      { this.props.active.exchange.exchCode }
                        &#58;
                      { this.props.active.market.displayName }
                    </b>
                    { ` ` }
  using 
                    { ` ` }
                    <b>
                        &quot;
                      { account.authNickname }
                        &quot;
                    </b>
                        &#58;
                  </p> 
                  <p>
                    <span className="ci-order-limit-sell">
  L
                    </span>
                    { ` ` }
                    <b>
                      { this.props.t(`app:sell`) }
                    </b>
                    { ` ` }
                    <b>
                      { parseFloat(this.state.orderQty) > 0 ? ` ` + this.state.orderQty : `` }
                      { ` ` }
                      { this.state.currencyText }
                    </b>
                    { ` ` }
                    { this.props.t(`for`) } 
                    { ` ` }
                    <b>
                      { this.state.priceTypeId !== 5 && parseFloat(this.state.orderTotal) > 0 ? ` ` + this.state.orderTotal : `` } 
                      { this.state.priceTypeId !== 5 && ` ` }
                      { this.state.baseCurrencyText }
                    </b> 
                    { ` at a price of ` }
                    <b>
                      { this.state.priceTypeId === 5 ? `~` + this.props.bestBid : this.state.limitPrice } 
                      { ` ` }
                      { this.state.baseCurrencyText }
                    </b>
                  </p>
                </div>)
          )
        }
        { isCGYActiveMarket &&
          <div className={ `order-details-text` } style={ { maxWidth: `75rem`, paddingTop: `1.5rem` } }>
            <span>
              { `I ` } 
            </span>
            <a target="_blank" rel="noopener noreferrer" href='https://bakkt.com/funds-transfer-auth-marketplace' style={ { color: `var(--brand-blue-)` } }>
              { `authorize` }
            </a>
            <span>
              { ` Bakkt Marketplace, LLC to transfer ${ parseFloat(this.state.orderQty) > 0 ? ` ` + this.state.orderQty : `` } ${ this.state.baseCurrencyText } from my account held at Bakkt Marketplace, 
              LLC to Bakkt Crypto Solutions, LLC to pay for my purchase of ${this.state.currencyText}. 
              The actual quantity of coins purchased may change due to volatility in the price of ${this.state.currencyText}, 
              but your order will be executed based on the best price available to Bakkt Crypto Solutions, LLC. 
              Cryptocurrency transactions are not FDIC or SIPC insured. ` }
            </span>
            <a target="_blank" rel="noopener noreferrer" href='https://bakkt.com/disclosures' style={ { color: `var(--brand-blue-)` } }>
              { `View Disclosures.` }
            </a>
            <div style={ { textAlign: `center`, padding: `1rem` } }>
              <span style={ { fontSize: `1.5rem` } }>
                <img src={ `${ window.WWW_URL }/assets/img/backed-by-bakkt.png` } height="32"/>
              </span>
            </div>
          </div>
        }
      </div>
    );
        
  }

  // This will either show the confirmation dialog, or if the user has "auto-confirm" selected, run submitOrder.
  confirmOrder(e?: any) { // previously-> confirmOrder(e?: any, callback?: () => void) {
    if (e) e.preventDefault();
    if (this.props.newOrderAutoConfirm) {
      parseFloat(this.state.orderQty) > 0 && this.submitOrder(e, () => this.updateTV());
    }else {
      parseFloat(this.state.orderQty) > 0 && this.openConfirmOrderModal();
    }
    if (!this.props.isDetached) this.props.updateBOHTab(1);
  }

  // This validates and submits the order. This where we're going to submit buy or sell
  submitOrder(e?: any, callback?: () => void) {
    if (e) e.preventDefault();

    this.setState({ orderStatusId: 1 }, function () {
      this.updateTV(this.props, this.state);
      this.closeConfirmOrderModal();
    });

    if (this.getApiVersion() == 1
      && this.state.mktId > 0
      && this.state.authId > 0
      && this.state.exchId > 0
    ) {
      let body: V1_Order = {
        mkt_id: this.state.mktId,
        auth_id: this.state.authId,
        limit_price: parseFloat(this.state.limitPrice),
        stop_price: parseFloat(this.state.priceTypeId == 3 ? this.state.limitPrice : this.state.stopPrice),
        order_quantity: this.state.orderTypeId == 1 ?
          parseFloat(this.state.orderTotal) :
          parseFloat(this.state.orderQty),
        order_total: this.state.orderTypeId == 1 ? parseFloat(this.state.orderQty) : parseFloat(this.state.orderTotal),
        order_operator: `-`,
        order_type_id: this.state.orderTypeId,
        order_type_text: this.state.orderTypeText,
        currency_text: this.state.orderTypeId == 1 ? this.state.currencyText : this.state.baseCurrencyText,
        price_type_id: this.state.priceTypeId,
        base_currency_text: this.state.orderTypeId == 1 ? this.state.baseCurrencyText : this.state.currencyText,
        exch_id: this.state.exchId
      };
      
      

      if (!this.validateV1Order(body)) { 
        emitEvent(NOTIFICATION, {
          notification_id: new Date().getTime(),
          title: `Error Validating Order`,
          title_vars: ``,
          message_raw: `Error Validating Order`,
          message: `Error Validating Order. Please double-check the parameters and try again.`,
          message_vars: ``,
          pinned: false,
          style: `error`,
          url: ``
        });
        return false; 
      } else {
        // V1 pending order
        const auth = this.props.accounts.find((a) => a.authId === body.auth_id) || {},
          exchange = this.props.exchanges.find((e) => e.exchId === body.exch_id) || {},
          market = this.props.markets.find((m) => m.marketId === body.mkt_id) || {};

        let pendingOrder = {
          authId: body.auth_id,
          authNickname: auth.authNickname,
          displayName: market.displayName,
          exchCode: exchange.exchCode,
          exchange: exchange.exchName,
          exchmktId: market.exchmktId,
          foreignOrderId: Math.floor(Math.random() * (1439418887 - 1449418887) + 1439418887),
          limitPrice: body.limit_price,
          market: market.marketName,
          orderCurrency: body.base_currency_text,
          orderId: Math.floor(Math.random() * (1439418887 - 1449418887) + 1439418887),
          orderOperator: body.order_operator,
          orderStatus: `Placing`,
          orderStatusId: 1,
          status: 1,
          side: body.order_type_id,
          orderTime: new Date().toISOString(),
          orderType: body.price_type_id,
          priceType: body.price_type_id === 3 ? `Limit` : `Stop (Limit)`,
          priceTypeId: body.price_type_id,
          quantity: body.order_quantity,
          quantityRemaining: body.order_quantity,
          quoteCurrency: body.currency_text,
          stopPrice: body.stop_price,
          autoDestroy: true,
        };
        emitEvent(PENDING_ORDER_ADDED, pendingOrder);
        // v1 order
        setTimeout(() => setV1Order(body, (orderId) => {
          const auth = this.props.accounts.find((a) => a.authId === body.auth_id) || {};
          const exchange = this.props.exchanges.find((e) => e.exchId === body.exch_id) || {};
          const market = this.props.markets.find((m) => m.marketId === body.mkt_id) || {};
          const data = {
            success: true,
            result: {
              authId: body.auth_id,
              authNickname: auth.authNickname,
              displayName: market.displayName,
              exchCode: exchange.exchCode,
              exchange: exchange.exchName,
              exchmktId: market.exchmktId,
              foreignOrderId: Math.floor(Math.random() * (1439418887 - 1449418887) + 1439418887),
              limitPrice: body.limit_price,
              market: market.marketName,
              orderCurrency: body.base_currency_text,
              orderId,
              orderOperator: body.order_operator,
              orderStatus: `Open`,
              orderStatusId: 2,
              orderTime: new Date().toISOString(),
              orderType: body.order_type_text,
              orderTypeId: body.order_type_id,
              priceType: body.price_type_id === 3 ? `Limit` : `Stop (Limit)`,
              priceTypeId: body.price_type_id,
              quantity: body.order_quantity,
              quantityRemaining: body.order_quantity,
              quoteCurrency: body.currency_text,
              stopPrice: body.stop_price
            }
          };
          if (data.success) {
            emitEvent(ORDER_ADDED, data.result);
            this.setState({
              ...this.getInitialState(),
              orderTypeId: this.state.orderTypeId,
              orderTypeText: this.state.orderTypeText,
              orderStatusId: 2,
              authId: this.state.authId
            }, () => { 
              callback && callback(); 
              this.resetOrderForm();
            });
          } else {
            emitEvent(ORDER_ADDED_FAILED);
          }
        }), 100);
      }
    } else if (this.getApiVersion() == 2
      && this.state.mktId > 0
      && this.state.authId > 0
      && this.state.exchId > 0) {
      let market = this.props.markets.find((m) => 
          (m.marketId === this.state.mktId && m.exchId === this.state.exchId) ) || {},
        orderTypeId = this.state.orderTypeId,
        orderTypeText = this.state.orderTypeText,
        priceTypeId = this.state.priceTypeId,
        limitPrice = this.state.limitPrice,
        orderQty = this.state.orderQty,
        orderTotal = this.state.orderTotal;

      if (!market) return;

      if (priceTypeId == 3) { // LIMIT
        
        let body: V2_Order = {
          ExchMktId: market.exchmktId,
          priceType: priceTypeId,
          side: orderTypeText,
          price: parseFloat(limitPrice),
          quantity: orderTypeId == 1 ?
            parseFloat(orderTotal) :
            parseFloat(orderQty)
        };

        if (!this.validateV2Order(body)) {
          emitEvent(NOTIFICATION, {
            notification_id: new Date().getTime(),
            title: `Error Validating Order`,
            title_vars: ``,
            message_raw: `Error Validating Order`,
            message: `Error Validating Order. Please double-check the parameters and try again.`,
            message_vars: ``,
            pinned: false,
            style: `error`,
            url: ``
          });

          return false;
        }else { 

          // V2 pending limit order
          const auth = this.props.accounts.find((a) => a.authId === this.state.authId) || {},
            exchange = this.props.exchanges.find((e) => e.exchId === this.state.exchId) || {};
            

          let pendingOrder = {
            authId: this.state.authId,
            authNickname: auth.authNickname,
            displayName: market.displayName,
            exchCode: exchange.exchCode,
            exchange: exchange.exchName,
            exchmktId: market.exchmktId,
            foreignOrderId: Math.floor(Math.random() * (1439418887 - 1449418887) + 1439418887),
            limitPrice: body.price,
            market: market.marketName,
            //orderCurrency: body.base_currency_text,
            orderId: Math.floor(Math.random() * (1439418887 - 1449418887) + 1439418887),
            //orderOperator: body.order_operator,
            orderStatus: `Placing Order`,
            orderStatusId: 1,
            status: 1,
            side: orderTypeId,
            orderTime: new Date().toISOString(),
            orderType: priceTypeId,
            priceType: priceTypeId === 3 ? `Limit` : `Stop (Limit)`,
            priceTypeId: priceTypeId,
            quantity: body.quantity,
            quantityRemaining: body.quantity,
            //quoteCurrency: body.currency_text,
            stopPrice: body.price,
            autoDestroy: true,
          };
      
      
          emitEvent(PENDING_ORDER_ADDED, pendingOrder);


          setTimeout(() => 
            setV2LimitOrder(this.state.authId, body, (rawData) => {
              if (rawData) {
                if (rawData.success) {
                  emitEvent(ORDER_ADDED, rawData.result);
                  this.setState({
                    ...this.getInitialState(),
                    orderTypeId: this.state.orderTypeId,
                    orderTypeText: this.state.orderTypeText,
                    orderStatusId: rawData.result.status,
                    authId: this.state.authId
                  }, () => { 
                    callback && callback(); 
                    this.resetOrderForm();
                  });
                } else {
                  emitEvent(ORDER_ADDED_FAILED);
                }
              } else {
                emitEvent(NOTIFICATION, {
                  notification_id: new Date().getTime(),
                  title: `Error Placing Order`,
                  title_vars: ``,
                  message_raw: `Error Placing Order`,
                  message: `Error Placing Order. Please double-check the parameters and try again.`,
                  message_vars: ``,
                  pinned: false,
                  style: `error`,
                  url: ``
                });
                this.resetOrderForm();
              }
            }), 100);
        }
      } else if (priceTypeId == 5) { // MARKET ORDER
        //console.log(`priceTypeId`, priceTypeId);

        let body: V2_Order = {
          ExchMktId: market.exchmktId,
          priceType: priceTypeId,
          side: orderTypeText,
          price: parseFloat(limitPrice),
          quantity: orderTypeId == 1 ?
            parseFloat(orderTotal) :
            parseFloat(orderQty)
        };

        //console.log(`body`, body);

        if (!this.validateV2Order(body)) {
          //console.log(`validate fail`);
          emitEvent(NOTIFICATION, {
            notification_id: new Date().getTime(),
            title: `Error Validating Order`,
            title_vars: ``,
            message_raw: `Error Validating Order`,
            message: `Error Validating Order. Please double-check the parameters and try again.`,
            message_vars: ``,
            pinned: false,
            style: `error`,
            url: ``
          });

          return false;
        }else { 

          // V2 pending limit order
          const auth = this.props.accounts.find((a) => a.authId === this.state.authId) || {},
            exchange = this.props.exchanges.find((e) => e.exchId === this.state.exchId) || {};
            

          let pendingOrder = {
            authId: this.state.authId,
            authNickname: auth.authNickname,
            displayName: market.displayName,
            exchCode: exchange.exchCode,
            exchange: exchange.exchName,
            exchmktId: market.exchmktId,
            foreignOrderId: Math.floor(Math.random() * (1439418887 - 1449418887) + 1439418887),
            limitPrice: body.price,
            market: market.marketName,
            //orderCurrency: body.base_currency_text,
            orderId: Math.floor(Math.random() * (1439418887 - 1449418887) + 1439418887),
            //orderOperator: body.order_operator,
            orderStatus: `Placing`,
            orderStatusId: 1,
            status: 1,
            side: orderTypeId,
            orderTime: new Date().toISOString(),
            orderType: priceTypeId,
            priceType: `Market`,
            priceTypeId: priceTypeId,
            quantity: body.quantity,
            quantityRemaining: body.quantity,
            //quoteCurrency: body.currency_text,
            stopPrice: body.price,
            autoDestroy: true,
          };

          //console.log(pendingOrder);
      
      
          emitEvent(PENDING_ORDER_ADDED, pendingOrder);

          //console.log(`side`, body.side);

          if (body.side == `Buy`) {
            //body.price = parseFloat(toFixedDecimals((this.props.bestAsk * 1.15), true, `price`, this.props.active.market));
            //console.log(body.price);
            //console.log(this.props.sells);
            // walk the orderbook and determine the price @ cumulative quantity slightly larger than the order quantity
            let runningSellQty = 0;
            let buyPrice = 0;
            this.props.sells.forEach((sell) => {
              if (runningSellQty > body.quantity) {
                if (buyPrice === 0) {
                  buyPrice = sell.price;
                }
              }
              runningSellQty += sell.quantity;
            });

            if (buyPrice === 0) {
              body.price = parseFloat(toFixedDecimals((this.props.bestAsk * 1.15), true, `price`, this.props.active.market));
            }else {
              body.price = buyPrice;
            }
            
          }else if (body.side == `Sell`) {
            //body.price = parseFloat(toFixedDecimals((this.props.bestBid * 0.85), true, `price`, this.props.active.market));
            //console.log(body.price);
            //console.log(this.props.buys);
            
            // walk the orderbook and determine the price @ cumulative quantity slightly larger than the order quantity
            let runningBuyQty = 0;
            let sellPrice = 0;
            this.props.buys.forEach((buy) => {
              //console.log(buy.price, buy.quantity, runningBuyQty, body.price);
              if (runningBuyQty > body.quantity) {
                if (sellPrice === 0) {
                  sellPrice = buy.price;
                }
              }
              runningBuyQty += buy.quantity;
            });

            if (sellPrice === 0) {
              body.price = parseFloat(toFixedDecimals((this.props.bestBid * 0.85), true, `price`, this.props.active.market));
            }else {
              body.price = sellPrice;
            }
          }

          setTimeout(() => 
            setV2LimitOrder(this.state.authId, body, (rawData) => {
              if (rawData) {
                if (rawData.success) {
                  emitEvent(ORDER_ADDED, rawData.result);
                  this.setState({
                    ...this.getInitialState(),
                    priceTypeId: this.state.priceTypeId,
                    orderTypeId: this.state.orderTypeId,
                    orderTypeText: this.state.orderTypeText,
                    orderStatusId: rawData.result.status,
                    authId: this.state.authId
                  }, () => { 
                    callback && callback(); 
                    this.resetOrderForm();
                  });
                } else {
                  emitEvent(ORDER_ADDED_FAILED);
                }
              } else {
                emitEvent(NOTIFICATION, {
                  notification_id: new Date().getTime(),
                  title: `Error Placing Order`,
                  title_vars: ``,
                  message_raw: `Error Placing Order`,
                  message: `Error Placing Order. Please double-check the parameters and try again.`,
                  message_vars: ``,
                  pinned: false,
                  style: `error`,
                  url: ``
                });
                this.resetOrderForm();
              }
            }), 100);
        }
      }
      else if (priceTypeId == 6) { // STOP (LIMIT)
        let body: V2_Order = {
          ExchMktId: market.exchmktId,
          TriggerExchMktId: this.state.stopMarketId,
          TriggerPrice: parseFloat(this.state.stopPrice),
          // BaseCurrCode: market.baseCurrCode,
          // QuoteCurrCode: market.quoteCurrCode,
          side: this.state.orderTypeText,
          price: parseFloat(this.state.limitPrice),
          quantity: orderTypeId == 1 ?
            parseFloat(this.state.orderTotal) :
            parseFloat(this.state.orderQty),
          
          priceType: priceTypeId
        };


        if (this.props.lastPrice && body.TriggerPrice) {
          if (parseFloat(this.props.lastPrice) > parseFloat(body.TriggerPrice)) {
            body.conditionalOperator = `LessThanOrEqual`;
          } else {
            body.conditionalOperator = `GreaterThanOrEqual`;
          }
        }else {
          body.conditionalOperator = `GreaterThanOrEqual`;
        }

        if (!this.validateV2Order(body)) {
          emitEvent(NOTIFICATION, {
            notification_id: new Date().getTime(),
            title: `Error Validating Order`,
            title_vars: ``,
            message_raw: `Error Validating Order`,
            message: `Error Validating Order. Please double-check the parameters and try again.`,
            message_vars: ``,
            pinned: false,
            style: `error`,
            url: ``
          });
          
          return false;
        }else {

          // V2 pending stop limit order
          const auth = this.props.accounts.find((a) => a.authId === this.state.authId) || {},
            exchange = this.props.exchanges.find((e) => e.exchId === this.state.exchId) || {};
            

          let pendingOrder = {
            authId: this.state.authId,
            authNickname: auth.authNickname,
            displayName: market.displayName,
            exchCode: exchange.exchCode,
            exchange: exchange.exchName,
            exchmktId: market.exchmktId,
            foreignOrderId: Math.floor(Math.random() * (1439418887 - 1449418887) + 1439418887),
            limitPrice: body.price,
            market: market.marketName,
            //orderCurrency: body.base_currency_text,
            orderId: Math.floor(Math.random() * (1439418887 - 1449418887) + 1439418887),
            //orderOperator: body.order_operator,
            orderStatus: `Placing Order`,
            orderStatusId: 1,
            status: 1,
            side: orderTypeId,
            orderTime: new Date().toISOString(),
            orderType: priceTypeId,
            priceType: priceTypeId === 3 ? `Limit` : `Stop (Limit)`,
            priceTypeId: priceTypeId,
            quantity: body.quantity,
            quantityRemaining: body.quantity,
            //quoteCurrency: body.currency_text,
            stopPrice: body.price,
            autoDestroy: true,
          };
      
      
          emitEvent(PENDING_ORDER_ADDED, pendingOrder);

          setTimeout(() =>
            setV2StopLimitOrder(this.state.authId, body, (rawData) => {
              if (rawData.success) {
                emitEvent(ORDER_ADDED, rawData.result);
                this.setState({
                  ...this.getInitialState(),
                  priceTypeId: this.state.priceTypeId,
                  orderTypeId: this.state.orderTypeId,
                  orderTypeText: this.state.orderTypeText,
                  orderStatusId: rawData.result.status,
                  authId: this.state.authId
                }, () => { 
                  callback && callback(); 
                  this.resetOrderForm();
                });
              } else {
                emitEvent(ORDER_ADDED_FAILED);
              }
            }), 100);
        }
      }
    }

    return true;
  }

  hasActiveMarket(): boolean {
    return !!this.props.active && !!this.props.active.market;
  }

  getApiVersion(props: Props = this.props, state: any = this.state): number {
    const exch = props.exchanges.find((e) => e.exchId == state.exchId);
    return  getExchangeTradeApiVersion(exch);
  }

  getActiveAccounts(props: Props = this.props, state: any = this.state): Array<Account> {
    if (!props.accounts) return [];
    
    return props.accounts.filter((account) =>
      account.authVersion == this.getApiVersion(props, state)
        && account.authExchId === state.exchId
        && account.authTrade 
        && account.authActive
    );
  }

  resetOrderForm() {
    //this.props.orderQty = 0;
    //this.props.orderTotal = 0;
    this.handleInputChange(`orderQty`, 0);
    this.handleInputChange(`orderTotal`, 0);
    this.setState({
      stopMarketId: this.props.active.market.exchmktId,
      stopMarketName: this.props.active.market.exchCode + `:` + this.props.active.market.marketName,
      stopPricePrecision: this.props.active.market.basePricePrecision,
    });
    this.setStopMarketIfLSCX();
    emitEvent(MARKET_BUY_MODE_DEACTIVATED);
    emitEvent(MARKET_SELL_MODE_DEACTIVATED);
    
  }

  getBalanceData() {
    return {
      buy: this.props.balances.find((balance) => {
        if (balance && (balance.balanceAuthId !== this.state.authId)) return false;

        return this.state.baseCurrencyText == balance.balanceCurrCode;
      }),
      sell: this.props.balances.find((balance) => {
        if (balance && (balance.balanceAuthId !== this.state.authId)) return false;
        return this.state.currencyText == balance.balanceCurrCode;
      })
    };
  }

  getBalance() {
    let balance = this.getBalanceData()[this.state.orderTypeText.toLowerCase()];

    if (balance) return balance.balanceAmountAvailable;
    return 0;
  }

  getBuyBalance() {
    let balance = this.getBalanceData()[`buy`];

    if (balance) return balance.balanceAmountAvailable;
    return 0;
  }

  getSellBalance() {
    let balance = this.getBalanceData()[`sell`];

    if (balance) return balance.balanceAmountAvailable;
    return 0;
  }

  getBalanceOptions(): Array<{
    label: string,
    value: any,
    compareValue?: any,
    icon?: any
  }> {
    if (this.getBalance() === 0) {
      return [];
    }
    return [1, 5, 10, 15, 25, 50, 75, 100].map((item) => {
      const active = this.props.active;
      const priceValue = (item / 100) * this.getBalance();
      let value = toFixedDecimals(priceValue);

      if (active && active.market) {
        value = toFixedDecimals(
          priceValue,
          this.state.orderTypeId == 2,
          `quantity`,
          active.market);
      }

      return {
        label: `${ item }% (${ value })`,
        value
      };
    });
  }

  getBuyBalanceOptions(): Array<{
    label: string,
    value: any,
    compareValue?: any,
    icon?: any
  }> {
    if (this.getBuyBalance() === 0) {
      return [];
    }
    return [1, 5, 10, 15, 25, 50, 75, 100].map((item) => {
      const active = this.props.active;
      const priceValue = (item / 100) * this.getBuyBalance();
      let value = toFixedDecimals(priceValue);

      if (active && active.market) {
        value = toFixedDecimals(
          priceValue,
          this.state.orderTypeId == 2,
          `quantity`,
          active.market);
      }

      return {
        label: `${ item }% (${ value })`,
        value
      };
    });
  }

  getSellBalanceOptions(): Array<{
    label: string,
    value: any,
    compareValue?: any,
    icon?: any
  }> {
    if (this.getSellBalance() === 0) {
      return [];
    }
    return [1, 5, 10, 15, 25, 50, 75, 100].map((item) => {
      const active = this.props.active;
      const priceValue = (item / 100) * this.getSellBalance();
      let value = toFixedDecimals(priceValue);

      if (active && active.market) {
        value = toFixedDecimals(
          priceValue,
          this.state.orderTypeId == 2,
          `quantity`,
          active.market);
      }

      return {
        label: `${ item }% (${ value })`,
        value
      };
    });
  }

  togglePriceCalculator() {
    this.setState({ allInCostCalculatorOpen: !this.state.allInCostCalculatorOpen });
  }

  refreshBalance() {
    if (!this.refreshEnabled()) return;

    emitEvent(REFRESH_BALANCE, this.state.authId);

    this.setState({
      refreshState: `animate`,
    }, () => setTimeout(() => {
      this.setState({
        refreshState: ``,
      });
      setTimeout(() => emitEvent(REFRESH_ACCOUNTS, this.state.authId), 5000);
    }, 1000));
  }

  refreshEnabled() {
    return this.getActiveAccounts().length > 0 && this.state.authId > 0;
  }

  accountStalenessClass() {
    let account = this.getActiveAccounts().find((a) => a.authId == this.state.authId);

    if (!account) return ``;

    let ms = +(new Date(forceUTCParsing(account.authFetched.toString()))),
      now = Date.now();

    if (account.authVersion == 2) {
      let balances = +(new Date(account.balancesRefreshed));
      let orders = +(new Date(account.ordersRefreshed));

      if (balances < orders) ms = balances;
      if (orders <= balances) ms = orders;
    }

    const SUPERFRESH = 60000,
      GREEN = 120000,
      YELLOW = 240000;

    
    if ((now - ms) <= SUPERFRESH) { return `fresh`; }
    if ((now - ms) <= GREEN) { return `green`; }
    if ((now - ms) <= YELLOW) { return `yellow`; }
    return `red`;
  }

  showNewAccountModal() {
    if (this.props.isDetached) {
      // Detached newOrder are too small to have New Account Modal inside them. its better to redirect fow now.
      this.props.redirectToAccounts && this.props.redirectToAccounts();
    } else {
      this.setState({
        newAccountModalShown: true
      });
    }
  }

  hideNewAccountModal() {
    this.setState({
      newAccountModalShown: false
    });
  }

  updateInputDirtiness = (dirty: boolean) => {
    this.setState({
      inputDirty: dirty
    });
  }


  displayOrderForm() {
    const singleOrderForm = <SingleOrderForm
      active={ this.props.active }
      markets={ this.props.markets }
      exchanges={ this.props.exchanges }
      hasActiveMarket={ this.hasActiveMarket() }
      tradingEnabled={ !!this.getApiVersion() }
      mktId={ this.state.mktId }
      exchId={ this.state.exchId }
      authId={ this.state.authId }
      lastPrice={ this.props.lastPrice }
      limitPrice={ this.state.limitPrice }
      stopPrice={ this.state.stopPrice }
      stopMarketId={ this.state.stopMarketId }
      stopMarketName={ this.state.stopMarketName }
      stopPricePrecision={ this.state.stopPricePrecision }
      setStopMarket={ this.setStopMarket.bind(this) }
      orderQty={ this.state.orderQty }
      orderTotal={ this.state.orderTotal }
      orderTypeId={ this.state.orderTypeId }
      orderTypeText={ this.state.orderTypeText }
      autoConfirm={ this.props.newOrderAutoConfirm }
      currencyText={ this.state.currencyText }
      priceTypeId={ this.state.priceTypeId }
      baseCurrencyText={ this.state.baseCurrencyText }
      options={ this.props.options }
      balanceData={ this.getBalanceData() }
      balanceOptions={ this.getBalanceOptions() }
      activeAccounts={ this.getActiveAccounts() }
      apiVersion={ this.getApiVersion() }
      onChange={ this.handleInputChange.bind(this) }
      onSubmit={ (e) => this.confirmOrder(e) }
      limitPriceError={ this.state.limitPriceError }
      stopPriceError={ this.state.stopPriceError }
      orderQuantityError={ this.state.orderQuantityError }
      orderTotalError={ this.state.orderTotalError }
      priceTypeError={ this.state.priceTypeError }
      addNewAccount={ this.showNewAccountModal.bind(this) }
      accountStalenessClass={ this.accountStalenessClass.bind(this) }
      refreshBalance={ this.refreshBalance.bind(this) }
      refreshEnabled={ this.refreshEnabled.bind(this) } 
      refreshState={ this.state.refreshState }
      resetOrderForm={ this.resetOrderForm.bind(this) } 
      updateOrderFormType={ this.props.updateOrderFormType && this.props.updateOrderFormType.bind(this) }
      isDetached={ !this.props.isDetached ? false : this.props.isDetached }
      showPrecision={ false }
      showMarketSelectorModal={ this.showMarketSelectorModal.bind(this) }
      hideMarketSelectorModal={ this.hideMarketSelectorModal.bind(this) }
      marketSelectorModalOpen={ this.state.marketSelectorModalOpen }
      updateInputDirtiness={ this.updateInputDirtiness.bind(this) } />;

    const dualOrderForm = <DualOrderForm
      active={ this.props.active }
      markets={ this.props.markets }
      exchanges={ this.props.exchanges }
      hasActiveMarket={ this.hasActiveMarket() }
      tradingEnabled={ !!this.getApiVersion() }
      mktId={ this.state.mktId }
      exchId={ this.state.exchId }
      authId={ this.state.authId }
      lastPrice={ this.props.lastPrice }
      limitPrice={ this.state.limitPrice }
      stopMarketId={ this.state.stopMarketId }
      stopMarketName={ this.state.stopMarketName }
      stopPricePrecision={ this.state.stopPricePrecision }
      setStopMarket={ this.setStopMarket.bind(this) }
      stopPrice={ this.state.stopPrice }
      orderQty={ this.state.orderQty }
      orderTotal={ this.state.orderTotal }
      orderTypeId={ this.state.orderTypeId }
      orderTypeText={ this.state.orderTypeText }
      autoConfirm={ this.props.newOrderAutoConfirm }
      currencyText={ this.state.currencyText }
      priceTypeId={ this.state.priceTypeId }
      baseCurrencyText={ this.state.baseCurrencyText }
      options={ this.props.options }
      balanceData={ this.getBalanceData() }
      buyBalanceOptions={ this.getBuyBalanceOptions() }
      sellBalanceOptions={ this.getSellBalanceOptions() }
      activeAccounts={ this.getActiveAccounts() }
      apiVersion={ this.getApiVersion() }
      onChange={ this.handleInputChange.bind(this) }
      onSubmit={ (e) => this.confirmOrder(e) }
      limitPriceError={ this.state.limitPriceError }
      stopPriceError={ this.state.stopPriceError }
      orderQuantityError={ this.state.orderQuantityError }
      orderTotalError={ this.state.orderTotalError }
      priceTypeError={ this.state.priceTypeError }
      addNewAccount={ this.showNewAccountModal.bind(this) }
      accountStalenessClass={ this.accountStalenessClass.bind(this) }
      refreshBalance={ this.refreshBalance.bind(this) }
      refreshEnabled={ this.refreshEnabled.bind(this) } 
      refreshState={ this.state.refreshState }
      resetOrderForm={ this.resetOrderForm.bind(this) }
      updateOrderFormType={ this.props.updateOrderFormType && this.props.updateOrderFormType.bind(this) }
      isDetached={ !this.props.isDetached ? false : this.props.isDetached }
      detachedOnChange={ this.props.detachedOnChange }
      showPrecision={ false }
      showMarketSelectorModal={ this.showMarketSelectorModal.bind(this) }
      hideMarketSelectorModal={ this.hideMarketSelectorModal.bind(this) }
      marketSelectorModalOpen={ this.state.marketSelectorModalOpen }
      updateInputDirtiness={ this.updateInputDirtiness.bind(this) }
      bestBid={ this.props.bestBid }
      bestAsk={ this.props.bestAsk } />;

    if (!this.props.orderFormType) {
      return singleOrderForm;
    }
    switch(this.props.orderFormType) {
    case `single`:
      return singleOrderForm;
    case `dual`:
      return dualOrderForm;
    default:
      return dualOrderForm;
    }
    
  }

  render() {
    return (
      <div className={ `new-order ${ this.state.mktId == -1 ? `has-no-market` : `` }` }>
        <div className="top">
          <div className="flex-row">
            <div className="new-order-titles">
              <span className="title">
                { this.props.t(`newOrder`) }
              </span>
              {
                this.hasActiveMarket() && (
                  <div className="title subtitle">
                    { this.props.marketLabel }
                  </div>
                )
              }
            </div>
            <div className="new-order-header-actions">
              {
                calculatorEneabledExchanges.includes(this.props.active.exchange.exchCode) && <div
                  onClick={ () => this.togglePriceCalculator() }
                  className={ `all-in-cost-calculator-button ${this.state.allInCostCalculatorOpen ? `active` : ``}` }>
                  { this.props.t(`priceCompare`) }
                </div>
              }
              {
                this.props.active.exchange.exchCode === `LSCX` && ( <LSCXButton accounts={ this.props.accounts } exchanges={ this.props.exchanges } /> )
              }
              {
                this.props.active.exchange.exchCode === `CGY` && ( <BakktButton accounts={ this.props.accounts } exchanges={ this.props.exchanges } /> )
              }
            </div>
          </div>
          { 
            this.state.allInCostCalculatorOpen && calculatorEneabledExchanges.includes(this.props.active.exchange.exchCode) ? 
              <AllInCostCalculator 
                t={ this.props.t } 
                active={ this.props.active } 
                exchanges={ this.props.exchanges } 
                calculatorEneabledExchanges={ calculatorEneabledExchanges } 
                lastPrice={ this.props.lastPrice }/> 
              : 
              null 
          }
        </div>
        {
          this.displayOrderForm()
        }
        {
          !this.props.newOrderAutoConfirm && this.state.confirmOrderModalOpen && (
            <ConfirmOrderModal
              label={ `Confirm Order` }
              orderText={ this.getOrderText() }
              confirm={ this.submitOrder.bind(this) }
              close={ this.closeConfirmOrderModal.bind(this) } />
          )
        }
        {
          this.state.newAccountModalShown && (
            <NewAccountModal
              t={ this.props.t }
              exchanges={ this.props.exchanges }
              currencies={ this.props.currencies }
              accounts={ this.props.accounts }
              active={ this.props.active }
              close={ this.hideNewAccountModal.bind(this) } />
          )
        }
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  newOrderAutoConfirm: state.app.newOrderAutoConfirm
});

const mapDispatchToProps = (dispatch) => ({
  updateNewOrderAutoConfirm: (autoConfirm) => dispatch(updateNewOrderAutoConfirm(autoConfirm))
});

export { NewOrder as PureNewOrder };
export default translate(`orders`)(connect(mapStateToProps, mapDispatchToProps)(NewOrder));
