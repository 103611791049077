// @flow
'use strict';

import React from 'react';
import { toFixedDecimalsHTML } from '../../helpers/NumberHelper.js';
import { adjustTradePrice } from '../../helpers/AdjustTradeHelper.js';
import type { Market } from "../../types/Market";

type Props = {
  market: Market,
  exchCode: string,
  maxBid: number,
  minAsk: number,
  lastPrice: number | null,
  lastTradeType: string,
  haveBidsAndAsks: boolean,
};

const MarketDepthChartExtraInfo = (props: Props) => {
  const { market, exchCode, maxBid = 0, minAsk = 0, lastPrice, lastTradeType, haveBidsAndAsks } = props;

  const midMarketPrice = ((minAsk - maxBid) / 2) + maxBid;
  const spreadSize = minAsk - maxBid;

  return (
    <div className={ `market-depth-chart-extra-info` }>
      { !!haveBidsAndAsks && <div className={ `info` }>
        <span className={ `label` }>
          { `Mid Market Price` }
        </span>
        <span
          className={ `value` }
          dangerouslySetInnerHTML={
            toFixedDecimalsHTML(midMarketPrice, false, `price`, market, false) 
          } />
      </div> }
      <div className={ `info` }>
        <span className={ `label` }>
          { `Last Trade Price` }
        </span>
        <span
          className={ `value` }
          dangerouslySetInnerHTML={
            lastPrice ?
              toFixedDecimalsHTML((exchCode == `LSCX` ? adjustTradePrice(lastPrice, lastTradeType) : lastPrice), false, `price`, market, false) :
              { __html: `loading` }
          } />
      </div>
      { !!haveBidsAndAsks && <div className={ `info` }>
        <span className={ `label` }>
          { `Spread Size` }
        </span>
        <span
          className={ `value` }
          dangerouslySetInnerHTML={
            toFixedDecimalsHTML(spreadSize, false, `price`, market, false) 
          } />
      </div> }
    </div>
  );
};

export default MarketDepthChartExtraInfo;
