// @flow
'use strict';

import React from 'react';
import { connect } from 'react-redux';
import MarketSwitcher from '../markets/MarketSwitcher/MarketSwitcher.jsx';
import { getMarketFromPanelSettings } from '../../helpers/MarketPairHelper.js';
import type { Market } from '../../types/Market.js';
import type { Exchange } from '../../types/Exchange.js';
import type { Account } from '../../types/Account.js';
import MarketSearch from '../markets/MarketSearch';

type Props = {
  exchanges: Array<Exchange>,
  markets: Array<Market>,
  accounts: Array<Account>,
  panel: any,
  setMarket: (exchCode: string, mktName: string) => void,
  exit: () => void,
  showLSCX: boolean,
  marketSwitcherVersion: string,
};

type State = {
  className: string
};

class MarketSelectorModal extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      className: ``
    };
  }

  render() {
    if (!this.props.panel) return null;
    // LSCX Stop Limits are not supported, we are removing them from MarketSelector but only for NewOrders not Boards.
    const markets = !this.props.showLSCX ? this.props.markets.filter((e) => e.exchCode !== `LSCX`) : this.props.markets;
    const settings = JSON.parse(this.props.panel.settingsJson || `{}`);
    let market = getMarketFromPanelSettings(this.props.markets, settings);
    

    if (!market) {
      market = markets.find((m) => m.exchCode == `CBSE` && m.displayName == `BTC/USD`);
    }

    if (!market) {
      return null;
    }

    let active = {
      market,
      // $FlowIgnore: suppressing this error
      exchange: this.props.exchanges.find((e) => e.exchId == market.exchId)
    };

    if (!active.exchange) return null;

    const MarketSwitcherComponent = this.props.marketSwitcherVersion === `v1` ? MarketSwitcher : MarketSearch;

    return (
      <div className="market-selector-modal">
        <div className="market-selector-modal-backdrop" onClick={ () => this.props.exit() } />
        <MarketSwitcherComponent
          useCallback
          callback={ this.props.setMarket }
          className={ this.state.className }
          exchanges={ this.props.exchanges }
          markets={ markets }
          accounts={ this.props.accounts }
          active={ {
            market: active.market,
            exchange: active.exchange
          } } />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  marketSwitcherVersion: state.app.marketSwitcherVersion,
});

export { MarketSelectorModal as PureMarketSelectorModal };

export default connect(mapStateToProps)(MarketSelectorModal);
